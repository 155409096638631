import React from 'react';
import './TopBar.css';
import person from './assets/person.svg';

export default function TopBar(props){
    const [displayProfileDropdown, setDisplayProfileDropdown] = React.useState(false);

    return (
        <div className='top-bar'>
            <h1>Blog - Admin</h1>
            <div className='menu'>
                <button onClick={() => props.setMode('list')}>Posts</button>
                <button onClick={() => props.setMode('media')}>Media</button>
                <button onClick={() => props.setMode('create')}>Create new</button>
            </div>
            <div className='user-details' onClick={() => setDisplayProfileDropdown(prev => !prev)}>
                <img src={person} alt='person' />
                <p>{props.user.email}</p>
                <div className='dropdown' style={{display: displayProfileDropdown ? 'block' : 'none'}}>
                    <div className='dropdown-item'><p>Profile</p></div>
                    <div className='dropdown-item'><p>Settings</p></div>
                    <div className='dropdown-item' onClick={props.logout}><p>Log out</p></div>
                </div> 
            </div>
        </div>
    );
}