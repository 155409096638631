import React from 'react';
import './AboutPage.css';
import Navbar from '../../Navbar/Navbar.js';
import AboutWeAreSection from './AboutWeAreSection/AboutWeAreSection';
import AboutFriendlyExpertsSection from './AboutFriendlyExpertsSection/AboutFriendlyExpertsSection';
import AboutBannerImg from './AboutBannerImg/AboutBannerImg';
import AboutDescription from './AboutDescription/AboutDescription';
import AboutDescriptionEnd from './AboutDescriptionEnd/AboutDescriptionEnd';
import OrchideaStats from './OrchideaStats/OrchideaStats';
import AboutValues from './AboutValues/AboutValues';
import AboutOwnCaseStudy from './AboutOwnCaseStudy/AboutOwnCaseStudy';
// import MeetTheTeam from './MeetTheTeam/MeetTheTeam';
import Footer from '../../Footer/Footer';

export default function AboutPage(){
    const meetTheTeamRef = React.useRef(null);

    const goToRef = () => {
        if (meetTheTeamRef.current) {
            meetTheTeamRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='about-page'>
            <Navbar buttonColor='#d8f7ee' buttonColorHover='#d9f3ec' colorText='#25262B' />
            <AboutWeAreSection />
            <AboutFriendlyExpertsSection goToRef={goToRef} />
            <AboutBannerImg />
            <AboutDescription />
            <AboutDescriptionEnd />
            <OrchideaStats />
            <AboutValues />
            <AboutOwnCaseStudy />
            {/* <MeetTheTeam meetTheTeamRef={meetTheTeamRef}/> */}
            <Footer />
        </div>
    );
}