import React from 'react';
import './CaseStudyPost.css';
import Navbar from '../../../Navbar/Navbar';
import Footer from '../../../Footer/Footer';
import { useParams } from 'react-router-dom';
import orchideaLogo from './assets/Group 77.svg';
import PostSuggestions from '../../../utils/PostSuggestions/PostSuggestions';

export default function CaseStudyPost(){
    const [post, setPost] = React.useState(null);
    const { id } = useParams();

    const fetchPost = async () => {
        const URL = `https://17uttfecb8.execute-api.eu-west-2.amazonaws.com/Prod/posts/get/${id}`;
        console.log(URL);
        const options = {
            method: 'GET'
        }
        try{
            const response = await fetch(URL, options);
            const response_data = await response.json();
            console.log(response_data);
            setPost(response_data);
        } catch (e){
            console.log(e);
            window.location.href = '/not-found';
        }
    }

    React.useEffect(() => {
        fetchPost()
    }, []);

    function RichTextContent(props) {
        return <div dangerouslySetInnerHTML={{ __html: props.content }} className='editor-content' />;
    }

    return (
        <div className='case-study'>
            <Navbar buttonColor='#d8f7ee' buttonColorHover='#d9f3ec' colorText='#25262B' />
            {post && <div className='case-study-page'>
                <div className='header-banner'>
                    {post.image.endsWith('.svg') ? 
                        (<object type='image/svg+xml' data={post.image}>Your browser does not support svg</object>) :
                        (<img src={post.image} alt='post-illustration' />)
                    }
                </div>
                <div className='post-core-top'>
                    <div className='left-side'>
                        <div className='website-company-profile'>
                            <div className='website' onClick={(e) => window.location.href = post.company_website}>
                                <p>Website</p>
                            </div>
                            <div className='company-profile'>
                                <p>{post.company_profile}</p>
                            </div>
                        </div>
                        <div className='target-profile'>
                            <div className='top'>
                                <p>Target profile</p>
                            </div>
                            <div className='bot'>
                                <p>{post.company_target_profile}</p>
                            </div>
                        </div>
                    </div>
                    <div className='right-side'>
                        <div className='introduction'>
                            <p>{post.post_intro}</p>
                        </div>
                        <div className='results'>
                            <p style={{fontWeight: 'bold'}}>Results</p>
                            <div className='blocks'>
                                <div className='block'>
                                    <img src={orchideaLogo} alt='orchidea' />
                                    <p className='value'>{post.response_rate}%</p>
                                    <p className='label'>response rate</p>
                                    <div className='highlight'></div>
                                </div>
                                <div className='block'>
                                    <img src={orchideaLogo} alt='orchidea' />
                                    <p className='value'>{post.lead_rate}%</p>
                                    <p className='label'>{post.service === 'leads' ? 'lead' : 'candidate'} rate</p>
                                    <div className='highlight'></div>
                                </div>
                                <div className='block'>
                                    <img src={orchideaLogo} alt='orchidea' />
                                    <p className='value'>£{post.cost_per_lead}</p>
                                    <p className='label'>cost per {post.service === 'leads' ? 'lead' : 'candidate'}</p>
                                    <div className='highlight'></div>
                                </div>
                            </div>
                        </div>
                        <div className='company-description'>
                            <p className='title'>Company Description</p>
                            <p className='content'>{post.company_description}</p>
                        </div>
                    </div> 
                </div>
                <hr />
                <div className='post-core-bottom'>
                    <div className='objectives-block'>
                        <p className='title'>Problem</p>
                        {<RichTextContent content={post.problem} />}
                    </div>
                    <div className='objectives-block'>
                        <p className='title'>Objectives</p>
                        {<RichTextContent content={post.objectives} />}
                    </div>
                    <div className='objectives-block'>
                        <p className='title'>Solution</p>
                        {<RichTextContent content={post.solution} />}
                    </div>
                    <div className='objectives-block'>
                        <p className='title'>Campaign Results</p>
                        {<RichTextContent content={post.campaignResults} />}
                    </div>
                    <div className='performance-block'>
                        <p className='title'>Campaign Performance Breakdown</p>
                        <table cellPadding={0} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>Prospects Engaged</th>
                                    <th>Channels</th>
                                    <th>Open rate</th>
                                    <th>Response rate</th>
                                    {post.service === 'leads' && <th>Lead rate</th>}
                                    {post.service === 'hire' && <th>Candidate rate</th>}
                                    {post.service === 'leads' && <th>Cost per lead</th>}
                                    {post.service === 'hire' && <th>Cost per candidate</th>}
                                    <th>Additional traffic / connections</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{post.prospects_engaged}</td>
                                    <td>{post.channels}</td>
                                    <td>{post.open_rate}%</td>
                                    <td>{post.response_rate}%</td>
                                    <td>{post.lead_rate}%</td>
                                    <td>£{post.cost_per_lead}</td>
                                    <td>{post.additional_traffic}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>}
            <PostSuggestions title='Explore other success stories' type='case-study' />
            <Footer />
        </div>
    )
}