import React from 'react';
import './OrchideaSpendIntro.css';
import orchidea_spend_intro_illustration from './assets/orchidea-spend-intro.svg';

export default function OrchideaSpendIntro(){
    return (
        <div className="orchidea-spend-intro">
            <img src={orchidea_spend_intro_illustration} alt="orchidea-spend" />
            <div className="orchidea-spend-intro-content">
                <h1>Orchidea<span>Spend</span></h1>
                <img src={orchidea_spend_intro_illustration} alt="orchidea-spend" className='illustration-responsive' />
                <p><span>Paid Advertisement & SEO done right is a fantastic way to gain brand awareness and a steady flow of inbound leads.</span></p>
                <p>By running paid advertisements for even just 1 month, can exponentially boost your <span>organic company growth</span> for months ahead. The longer they run, the better rippling effect. A true helping hand that synergises perfectly with proactive outbound growth efforts.</p>
                <p><span>Orchidea PPC experts will help you</span> create, monitor, optimise and deliver results to generate better leads, applicants or website traffic.</p>
                <div className="orchidea-spend-intro-buttons">
                    <button onClick={() => window.location.href = '/contact'}>Book a Call</button>
                    <button className="learn-more" onClick={() => window.location.href = '/spend'}>Learn more</button>
                </div>
            </div>
        </div>
    );
}