import React from 'react';
import './OrchideaSpendWithWithout.css';
import seo_visibility from './assets/without/seo_visibility.svg';
import wasted_budget from './assets/without/wasted_budget.svg';
import lack_of_time from './assets/without/lack_of_time.svg';
import inadequate_roi from './assets/without/inadequate_roi.svg';
import without_flower_left from './assets/without/flower_left.svg';
import without_flower_right from './assets/without/flower_right.svg';
import with_flower_left from './assets/with/flower_left.svg';
import with_flower_right from './assets/with/flower_right.svg';
import lets_bloom from './assets/with/lets-bloom.png';
import seo_auditing from './assets/with/seo_auditing.svg';
import cpc_results from './assets/with/cpc_results.svg';
import end_to_end from './assets/with/end_to_end.svg';
import improved_roi from './assets/with/improved_roi.svg';

export default function OrchideaSpendWithWithout(){
    const [withOrchidea, setWithOrchidea] = React.useState(false);

    return (
         <div className='orchidea-spend-with-without'>
            {!withOrchidea && <div className='without'>
                <div className='top-side'>
                    <div className='toggle'>
                        <div className='inside-circle' onClick={() => setWithOrchidea(prev => !prev)}></div>
                        <p className='inside-text'>Without <br /> Orchidea<span>Spend</span></p> 
                    </div>
                    <img src={lets_bloom} alt='lets-bloom' className='fade-in'/>
                </div>
                <div className='items'>
                    <div className='item'>
                        <p className='title'>LACK OF <br /> SEO VISIBILITY</p>
                        <img src={seo_visibility} alt='seo_visibility'/>
                        <div className='highlight'>
                            <p>Lack of brand awareness</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>WASTED BUDGET <br /> SPEND</p>
                        <img src={wasted_budget} alt='wasted_budget'/>
                        <div className='highlight'>
                            <p>Ineffective campaigns</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>LACK OF <br /> TIME / EXPERTISE</p>
                        <img src={lack_of_time} alt='lack-of-time'/>
                        <div className='highlight'>
                            <p>Lack of Resources</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>INADEQUATE <br /> ROI</p>
                        <img src={inadequate_roi} alt='inadequate_roi'/>
                        <div className='highlight'>
                            <p>Lost Revenue</p>
                        </div>
                    </div>
                </div>  
                <img src={without_flower_left} alt='without-flower-left' className='left-flower fade-in'/>  
                <img src={without_flower_right} alt='without-flower-right' className='right-flower fade-in'/>  
            </div>}
            {withOrchidea && <div className='with'>
                <div className='top-side'>
                    <div className='toggle'>
                        <p className='inside-text'>With <br /> Orchidea<span>Spend</span></p> 
                        <div className='inside-circle' onClick={() => setWithOrchidea(prev => !prev)}></div>
                    </div>
                </div>
                <div className='items'>
                    <div className='item'>
                        <p className='title'>SEO AUDITING & <br /> OPTIMIZATION</p>
                        <img src={seo_auditing} alt='seo_auditing'/>
                        <div className='highlight'>
                            <p>High Brand Visibility</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>HYPER EFFECTIVE <br /> CPC RESULTS</p>
                        <img src={cpc_results} alt='cpc_results'/>
                        <div className='highlight'>
                            <p>Successful campaigns</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>WE HANDLE IT <br /> END-TO-END</p>
                        <img src={end_to_end} alt='end_to_end'/>
                        <div className='highlight'>
                            <p>Time Availibity & Results</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>CONSISTENTLY <br /> IMPROVED ROI</p>
                        <img src={improved_roi} alt='improved_roi'/>
                        <div className='highlight'>
                            <p>Gained Revenue</p>
                        </div>
                    </div>
                </div>  
                <div className='text'>
                    <p>
                        <span>Targeted ad campaigns, compelling ad creatives, accurate performance</span>
                        tracking, and efficient budget allocation to improve the effectiveness of the ad campaign.
                    </p>
                    <p style={{marginTop: '30px'}}>
                        <span>With us, your marketing teams can focus on other critical aspects 
                        of the marketing strategy</span>, while we handle the complex and time-consuming 
                        task of managing the ad campaign.
                    </p>
                </div>
                <img src={with_flower_left} alt='without-flower-left' className='left-flower fade-in'/>  
                <img src={with_flower_right} alt='without-flower-right' className='right-flower fade-in'/>  
            </div>}
        </div>
    )
}