import React from 'react';
import './OrchideaLeadsCounter.css';

export default function OrchideaLeadsCounter(){
    const [minutes, setMinutes] = React.useState(59);
    const [seconds, setSeconds] = React.useState(59);
  
    React.useEffect(() => {
      const interval = setInterval(() => {
        if (seconds > 1) {
          setSeconds(seconds - 1);
        } else if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          // clear interval if both minutes and seconds reach 0
          clearInterval(interval);
        }
      }, 1000);
      // cleanup function
      return () => {
        clearInterval(interval);
      };
    }, [minutes, seconds]);

    return (
        <div className='orchidea-leads-counter'>
            <div className='counter-widget'>
                <div className='widget-top'>
                    <div className='widget-column'>
                        <p>Day</p>
                        <div className='number'>
                            <p>13</p>
                        </div>
                    </div>
                    <div className='widget-column'>
                        <p>Hours</p>
                        <div className='number'>
                            <p>23</p>
                        </div>
                    </div>
                    <div className='widget-column'>
                        <p>Minutes</p>
                        <div className='number'>
                            <p>{minutes}</p>
                        </div>
                    </div>
                    <div className='widget-column'>
                        <p>Seconds</p>
                        <div className='number'>
                            <p>{seconds}</p>
                        </div>
                    </div>
                </div>
                <div className='widget-bottom'>
                    <p>Your new hiring process could be live in <span>14 days</span></p>
                </div>
            </div>
            <div className='counter-text'>
                <p>Book in a call with one of our experts to see a <span>live demo</span>, and learn more about how we may <span>help your business grow</span>.</p>
            </div>
        </div>
    )
}