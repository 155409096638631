import React from 'react';
import './OrchideaLeadsWithWithout.css';
import lack_of_expertise from './assets/without/lack_of_expertise.svg';
import limited_audience from './assets/without/limited_audience.svg';
import lack_of_time from './assets/without/lack_of_time.svg';
import lack_of_technology from './assets/without/lack_of_technology.svg';
import without_flower_left from './assets/without/flower_left.svg';
import without_flower_right from './assets/without/flower_right.svg';
import with_flower_left from './assets/with/flower_left.svg';
import with_flower_right from './assets/with/flower_right.svg';
import dedicated_team from './assets/with/team.svg';
import global_contacts from './assets/with/global_contacts.svg';
import handle_the_work from './assets/with/handle_the_work.svg';
import tech from './assets/with/tech.svg';
import lets_bloom from './assets/with/lets-bloom.png';

export default function OrchideaLeadsWithWithout(){
    const [withOrchidea, setWithOrchidea] = React.useState(false);

    return (
         <div className='orchidea-leads-with-without'>
            {!withOrchidea && <div className='without'>
                <div className='top-side'>
                    <div className='toggle'>
                        <div className='inside-circle' onClick={() => setWithOrchidea(prev => !prev)}></div>
                        <p className='inside-text'>Without <br /> Orchidea<span>Leads</span></p> 
                    </div>
                    <img src={lets_bloom} alt='lets-bloom' className='fade-in'/>
                </div>
                <div className='items'>
                    <div className='item'>
                        <p className='title'>LACK OF <br /> EXPERTISE</p>
                        <img src={lack_of_expertise} alt='lack-of-expertise'/>
                        <div className='highlight'>
                            <p>Ineffective camapigns</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>LIMITED <br /> AUDIENCE</p>
                        <img src={limited_audience} alt='limited-audience'/>
                        <div className='highlight'>
                            <p>Expensive Contact Sourcing</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>LACK OF <br /> TIME</p>
                        <img src={lack_of_time} alt='lack-of-time'/>
                        <div className='highlight'>
                            <p>Limited time & training</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>LACK OF <br /> TECHNOLOGY</p>
                        <img src={lack_of_technology} alt='lack-of-technology'/>
                        <div className='highlight'>
                            <p>Lost Revenue</p>
                        </div>
                    </div>
                </div>  
                <img src={without_flower_left} alt='without-flower-left' className='left-flower fade-in'/>  
                <img src={without_flower_right} alt='without-flower-right' className='right-flower fade-in'/>  
            </div>}
            {withOrchidea && <div className='with'>
                <div className='top-side'>
                    <div className='toggle'>
                        <p className='inside-text'>With <br /> Orchidea<span>Leads</span></p> 
                        <div className='inside-circle' onClick={() => setWithOrchidea(prev => !prev)}></div>
                    </div>                    
                </div>
                <div className='items'>
                    <div className='item'>
                        <p className='title'>DEDICATED TEAM <br /> OF SENIOR EXPERTS</p>
                        <img src={dedicated_team} alt='lack-of-expertise'/>
                        <div className='highlight'>
                            <p>Successful Campaigns</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>GLOBAL B2B CONTACT <br /> DATABASES & NETWORKS</p>
                        <img src={global_contacts} alt='limited-audience'/>
                        <div className='highlight'>
                            <p>Contact Provision</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>WE HANDLE <br /> ALL THE WORK</p>
                        <img src={handle_the_work} alt='lack-of-time'/>
                        <div className='highlight'>
                            <p>Time for Priorities</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>WE PROVIDE <br /> THE AUTOMATION TECH</p>
                        <img src={tech} alt='lack-of-technology'/>
                        <div className='highlight'>
                            <p>Gained Revenue</p>
                        </div>
                    </div>
                </div>  
                <div className='text'>
                    <p>
                        End to End prospecting process handled for you, without worry of strategy, 
                        messaging, or technology stack - <span style={{color: '#674AFF'}}>Just Results</span>. <span>Scale & Adapt</span> to your business needs, rapidly and risk free.  
                    </p>
                    <p style={{marginTop: '30px'}}>
                        <span>Your sales reps can focus on closing deals and growing your 
                        business</span>,while we handle the time-consuming and complex task of prospecting.
                    </p>
                </div>
                <img src={with_flower_left} alt='without-flower-left' className='left-flower fade-in'/>  
                <img src={with_flower_right} alt='without-flower-right' className='right-flower fade-in'/>  
            </div>}
        </div>
    )
}