import React from 'react';
import './MediaItem.css';
import file_icon from './assets/file.svg';

export default function MediaItem(props){
    const [seeLink, setSeeLink] = React.useState(false);
    return (
        <div className="media-item">
            {props.fileType === 'image' && props.mediaURL.endsWith('.svg') ? 
                (<object type='image/svg+xml' data={props.mediaURL}>Your browser does not support svg</object>) :
                (<img src={props.mediaURL} alt='file icon' />)
            }
            {props.fileType === 'video' && <video controls src={props.mediaURL}></video>}
            {props.fileType === 'other' && <img src={file_icon} alt="file icon" />}
            <div className='links'>
                <p 
                    onClick={() => setSeeLink(prev => !prev)}
                    className='link-btn'
                >{seeLink ? 'Hide Link' : 'See Link'}</p>
                {seeLink && <p>{props.mediaURL}</p>}
                <a href={props.mediaURL}>Open</a>
            </div>  
        </div>
    );
}