import React from 'react';
import './Page.css';    
import { Outlet } from 'react-router-dom';

export default function Page(){
    return (
        <div className='page'>
            <Outlet />
        </div>
    );
}