import React from 'react';
import './BlogsIntro.css';
import { Link } from 'react-router-dom';

export default function BlogsIntro(){
    return (
        <div className='blogs-intro'>
            <div className='top-text'>
                <p><Link to='/'>Home</Link> / Blog</p>
            </div>
            <div className='text-content'>
                <p>
                    <span>No matter the department you oversee</span>, be it sales, marketing, PPC, SEO, or recruitment, 
                    <span>Orchidea's success stories provide proof of what's achievable</span>. <br /> 
                    We take ownership of the outcomes and take pride in every single transformation we facilitate. 
                    Delve into the examples below and discover the potential of your business to 
                    sell more effectively or optimize recruitment efforts with <span>Orchidea's assistance</span>.
                </p>
            </div>
            <div className='buttons'>
                <button onClick={() => window.location.href = '/contact'}>Let's Bloom</button>
                <button className="learn-more" onClick={() => window.location.href = '/pricing'}>See pricing</button>
            </div>
        </div>
    );
}