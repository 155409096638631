import React from 'react';
import './BoostOrchideaSpend.css';
import illustration from './assets/boost-illustration.png';

export default function BoostOrchideaSpend(){
    return (
        <div className='boost-orchidea-spend'>
            <div className='boost-orchidea-spend--content'>
                <p className='text-desktop'>By <br />putting in money,<br /> <span>you can greatly<br /> BOOST your<br /> results and speed</span><br /> by up to 50x results,<br /> in ¼ of the time!</p>
                <p className='text-mobile'>But putting in money, <span>you can greatly BOOST your results and speed</span> by up to 50x results, in ¼ of the time!</p>
            </div>
            <div className='boost-orchidea-spend--illustration'>
                <img src={illustration} alt='illustration' />
                <p className='caption'>Support your lead generation or recruitment campaigns ran by us with Orchidea Spend. Exponentially multiply your results.</p>
            </div>
        </div>
    )
}