import React from 'react';
import './Footer.css';
import orchidea_footer_logo from './assets/logo-footer.svg';
import facebook_logo from './assets/facebook.svg';
import instagram_logo from './assets/instagram.svg';
import twitter_logo from './assets/twitter.svg';
import linkedin_logo from './assets/linkedin.svg';
import email_icon from './assets/email-icon.svg';
import chevron_up from './assets/chevron-up.svg'
import chevron_down from './assets/chevron-down.svg'
import {Link} from 'react-router-dom'

export default function Footer(){
    const [servicesClicked, setServicesClicked] = React.useState(false);
    const [quickLinksClicked, setQuickLinksClicked] = React.useState(false);
    const [resourcesClicked, setResourcesClicked] = React.useState(false);
    const [legalClicked, setLegalClicked] = React.useState(false);
    const [windowWidthSmall, setWindowWidthSmall] = React.useState(false);

    React.useEffect(() => {
        const monitorWindowWidth = () => {
            if (window.innerWidth < 951) {
                setWindowWidthSmall(true);
            } else {
                setWindowWidthSmall(false);
            }
        };
        window.addEventListener('resize', monitorWindowWidth);
        monitorWindowWidth();
    }, []);

    return (
        <div className='footer'>
            <div className="footer-main">
                <div className="left-container">
                    <img src={orchidea_footer_logo} alt="" className="logo" onClick={() => window.location.href='/'} />
                    <div className="email-box">
                        <img src={email_icon} alt="" />
                        <p>info@orchidea.io</p>
                    </div>
                    <div className="lets-bloom">
                        <p>Let's Bloom</p>
                    </div>
                </div>
                <div className="services-container">
                    <div className="footer-sub-title" onClick={() => setServicesClicked(prev => !prev)}>
                        <h2>Services</h2>
                        <img src={servicesClicked ? chevron_up : chevron_down} alt='chevron-up' />
                    </div>
                    <Link to="/leads" style={{display: windowWidthSmall ? (servicesClicked ? 'inline-block' : 'none') : 'inline-block'}}>Orchidea Leads</Link>
                    <Link to="/hire" style={{display: windowWidthSmall ? (servicesClicked ? 'inline-block' : 'none') : 'inline-block'}}>Orchidea Hire</Link>
                    <Link to="/spend" style={{display: windowWidthSmall ? (servicesClicked ? 'inline-block' : 'none') : 'inline-block'}}>Orchidea Spend</Link>
                </div>
                <div className="quick-links-container">
                    <div className="footer-sub-title" onClick={() => setQuickLinksClicked(prev => !prev)}>
                        <h2>Quick Links</h2>
                        <img src={quickLinksClicked ? chevron_up : chevron_down} alt='chevron-up' />
                    </div>
                    <Link to="/about" style={{display: windowWidthSmall ? (quickLinksClicked ? 'inline-block' : 'none') : 'inline-block'}}>About</Link>
                    <Link to="/contact" style={{display: windowWidthSmall ? (quickLinksClicked ? 'inline-block' : 'none') : 'inline-block'}}>Contact</Link>
                    {/* <Link to="" style={{display: windowWidthSmall ? (quickLinksClicked ? 'inline-block' : 'none') : 'inline-block'}}>Blog</Link> */}
                    <Link to="/pricing" style={{display: windowWidthSmall ? (quickLinksClicked ? 'inline-block' : 'none') : 'inline-block'}}>Pricing</Link>
                    <Link to="" style={{display: windowWidthSmall ? (quickLinksClicked ? 'inline-block' : 'none') : 'inline-block'}}>Careers</Link>
                </div>
                <div className="resources-container">
                    <div className="footer-sub-title" onClick={() => setResourcesClicked(prev => !prev)}>
                        <h2>Resources</h2>
                        <img src={resourcesClicked ? chevron_up : chevron_down} alt='chevron-up' />
                    </div>
                    <Link to="/blog" style={{display: windowWidthSmall ? (resourcesClicked ? 'inline-block' : 'none') : 'inline-block'}}>Blog posts</Link>
                    {/* <Link to="" style={{display: windowWidthSmall ? (resourcesClicked ? 'inline-block' : 'none') : 'inline-block'}}>Videos</Link> */}
                    <Link to="/case-studies" style={{display: windowWidthSmall ? (resourcesClicked ? 'inline-block' : 'none') : 'inline-block'}}>Case studies</Link>
                    {/* <Link to="" style={{display: windowWidthSmall ? (resourcesClicked ? 'inline-block' : 'none') : 'inline-block'}}>Events</Link>
                    <Link to="" style={{display: windowWidthSmall ? (resourcesClicked ? 'inline-block' : 'none') : 'inline-block'}}>Guides</Link> */}
                </div>
                <div className="legal-container">
                    <div className="footer-sub-title" onClick={() => setLegalClicked(prev => !prev)}>
                        <h2>Legal</h2>
                        <img src={legalClicked ? chevron_up : chevron_down} alt='chevron-up' />
                    </div>
                    <Link to="" style={{display: windowWidthSmall ? (legalClicked ? 'inline-block' : 'none') : 'inline-block'}}>Privacy Policy</Link>
                    <Link to="" style={{display: windowWidthSmall ? (legalClicked ? 'inline-block' : 'none') : 'inline-block'}}>Terms & Conditions</Link>
                    <Link to="" style={{display: windowWidthSmall ? (legalClicked ? 'inline-block' : 'none') : 'inline-block'}}>Cookies Policy</Link>
                    <Link to="" style={{display: windowWidthSmall ? (legalClicked ? 'inline-block' : 'none') : 'inline-block'}}>GDPR Compliance</Link>
                    <Link to="" style={{display: windowWidthSmall ? (legalClicked ? 'inline-block' : 'none') : 'inline-block'}}>Opt-Out</Link>
                </div>
            </div>

            <hr className='separator' />

            <div className="footer-bottom">
                <p>Copyright 2023, Orchidea all rights reserved.</p>
                <div className="socials">
                    <img src={linkedin_logo} alt="linkedin" />
                    <img src={instagram_logo} alt="instagram" />
                    <img src={twitter_logo} alt="twitter" />
                    <img src={facebook_logo} alt="facebook" />
                </div>
                <p>1 Oxford St, W1D 1NX, London, United Kingdom</p>
            </div>
        </div>
    );
}