import React from 'react';
import './AboutValues.css';

export default function AboutValues(){
    return (
        <div className='about-values'>
            <h2>Our Core Values</h2>
            <p>We are incredibly proud of who we are, what we do, and how we do it.</p>
            <div className='values'>
                <div className='value'>
                    <p><span>Client-Centric</span> <br /> Approach</p>
                </div>
                <div className='value' id='about-values--mid-value'>
                    <p><span>Quality</span> & <br /> <span>Integrity</span></p>
                </div>
                <div className='value'>
                    <p>Continuous <br /> <span>Innovation</span></p>
                </div>
            </div>
        </div>
    );
}