import React from 'react';
import './OrchideaLeadsIntro.css';
import orchidea_leads_intro_illustration from './assets/orchidea-leads-intro.svg';

export default function OrchideaLeadsIntro(){
    return (
        <div className="orchidea-leads-intro">
            <div className="orchidea-leads-intro-content">
                <h1>Orchidea<span>Leads</span></h1>
                <img src={orchidea_leads_intro_illustration} alt="orchidea-leads" className='illustration-responsive' />
                <p><span>Growing your business & acquiring new customers can be hard and time consuming, but it doesn't have to be!</span></p>
                <p>Orchidea uses a cutting-edge approach of proprietary automation technology, multiple global networks & databases, and experienced professionals on multichannel outbound prospecting.</p>
                <p>We create a <span>hyper-targeted strategy</span> to manually source & engage with potential clients to schedule in for you when they're ready & provide monthly feedback reports for data analysis & optimisation.</p>
                <div className="orchidea-leads-intro-buttons">
                    <button onClick={() => window.location.href = '/contact'}>Book a Call</button>
                    <button className="learn-more" onClick={() => window.location.href = '/leads'}>Learn more</button>
                </div>
            </div>
            <img src={orchidea_leads_intro_illustration} alt="orchidea-leads" />
        </div>
    );
}