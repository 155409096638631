import React from 'react';
import './AboutDescription.css';
import check_mark from './assets/check-mark.svg';

export default function AboutDescription(){
    return (
        <div className='about-description'>
            <div className='about-description--inside'>
                <div className='text-highlighted'>
                    <img src={check_mark} alt='checked' className='check-mark' />
                    <h2>Orchidea is so much more than just a tool.</h2>
                    <div className='highlight' id='highlight-1'></div>
                </div>
                <p>We are smart humans with unrivalled expertise and powerful proprietary technology, databases, and networks - dedicated to empowering our customers.</p>
                <div className='text-highlighted'>
                    <img src={check_mark} alt='checked' className='check-mark' />
                    <h2>We use a personalised approach to our campaigns</h2>
                    <div className='highlight' id='highlight-2'></div>
                </div>
                <p>Working with both startups to large corporations in a cornucopia of industries. Helping businesses generate bigger profits by building awareness, driving traffic, connecting with customers and candidates - growing their overall sales and team size!</p>
            </div>
        </div>
    );
}