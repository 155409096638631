import React from 'react';
import './HomePageServices.css';
import hire_logo from './assets/hire.svg';
import leads_logo from './assets/leads.svg';
import spend_logo from './assets/spend.svg';

export default function HomePageServices(){
    return (
        <div className="home-page-services">
            <div className="home-page-services--title">
                <h1><span>Orchidea</span> Services</h1>
            </div>
            <div className="home-page-services--services">
                <div className="home-page-services--service">
                    <img src={leads_logo} alt="orchidea-leads" className="logo" />
                    <h2 className="title">Orchidea<span>Leads</span></h2>
                    <p>Outbound Lead Generation & appointment setting services</p>
                    <button onClick={() => window.location.href = '/leads'}>Learn more</button>
                    <button onClick={() => window.location.href = '/leads'} className='button-plus'>+</button>
                </div>
                <div className="home-page-services--service" style={{margin: '0 30px 0 30px'}}>
                    <img src={hire_logo} alt="orchidea-hire" className="logo" />
                    <h2 className="title">Orchidea<span>Hire</span></h2>
                    <p>Bespoke Headhunting & Recruitment Services</p>
                    <button onClick={() => window.location.href = '/hire'}>Learn more</button>
                    <button onClick={() => window.location.href = '/hire'} className='button-plus'>+</button>
                </div>
                <div className="home-page-services--service">
                    <img src={spend_logo} alt="orchidea-spend" className="logo" />
                    <h2 className="title">Orchidea<span>Spend</span></h2>
                    <p>End-to-End Inbound Ad campaign setting & Management</p>
                    <button onClick={() => window.location.href = '/spend'}>Learn more</button>
                    <button onClick={() => window.location.href = '/spend'} className='button-plus'>+</button>
                </div>
            </div>
            <div className="home-page-services--button-container">
                <button onClick={() => window.location.href = '/contact'}>Book a Call</button>
            </div>
    </div>
    );
}