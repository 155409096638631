import React from 'react';
//import { Link } from 'react-router-dom';
import './NavbarItem.css'

export default function NavbarItem(props){

    return(
        <div className='navbar--item'>
            <a href={props.to}>{props.label}</a>
        </div>
    )

}