import React from 'react';
import './BlogsPage.css';
import Navbar from '../../Navbar/Navbar';
import ResourceBanner from '../../utils/ResourceBanner/ResourceBanner';
import bannerImg from './assets/banner-img.svg';
import BlogsIntro from './BlogsIntro/BlogsIntro';
import Footer from '../../Footer/Footer';
import PostsSection from '../../utils/PostsSection/PostsSection';

export default function BlogsPage(){
    return (
        <div className='blogs-page'>
            <Navbar buttonColor='#d8f7ee' buttonColorHover='#d9f3ec' colorText='#25262B' />
            <ResourceBanner image={bannerImg} title='Blog posts' subtitle='Your Success is Our Succes' />
            <BlogsIntro />
            <PostsSection pageTitle='All blog posts' type='blog'/>
            <Footer />
        </div>
    );
}