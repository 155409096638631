import React from 'react';
import './PricingPage.css';
import Navbar from '../../Navbar/Navbar';
import PricingBanner from './PricingBanner/PricingBanner';
import PricingWidget from './PricingWidget/PricingWidget';
import Footer from '../../Footer/Footer';
import LetsBloomSection from '../../utils/LetsBloomSection/LetsBloomSection';

export default function PricingPage() {
    return (
        <div className='pricing-page'>
            <Navbar buttonColor='#d8f7ee' buttonColorHover='#d9f3ec' colorText='#25262B' />
            <PricingBanner/>
            <PricingWidget />
            <LetsBloomSection />
            <Footer />
        </div>
    );
}