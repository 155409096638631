import React from 'react';
import OrchideaLeadsIntro from './OrchideaLeadsIntro/OrchideaLeadsIntro';
import OrchideaLeadsBanner from './OrchideaLeadsBanner/OrchideaLeadsBanner';
import OrchideaLeadsWidget from './OrchideaLeadsWidget/OrchideaLeadsWidget';
import OrchideaLeadsForecast from '../../../utils/OrchideaLeadsForecast/OrchideaLeadsForecast';

export default function OrchideaLeads(){
    return (
        <div>
             <OrchideaLeadsIntro />
             <OrchideaLeadsBanner />
             <OrchideaLeadsWidget />
             <OrchideaLeadsForecast />
        </div>
       
    );
}