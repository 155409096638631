import React from 'react';
import './CaseStudiesOrchideaSpend.css';
import edot_graf_illustration from './assets/edot_graf.svg';
import eyetouch_illustration from './assets/eyetouoch.svg';
import flowerfly_illustration from './assets/flowerfly.svg';

export default function CaseStudiesOrchideaSpend(){
    return (
        <div className='case-studies'>
            <h1>Our Clients - Case studies</h1>
            <p>Take a look at some of our succss stories with clients on <strong>Orchidea<span>Spend</span></strong>.</p>
            <div className='items'>
                <div className='item'>
                    <div className='item--image'>
                        <img src={flowerfly_illustration} alt='flowerfly_illustration' />
                    </div>
                    <div className='item--content'>
                        <h3>Flowerfly</h3>
                        <p>How less is more, when good segmentation is in place.</p>
                        <div className='highlight'><p>+25% conversion rate</p></div>
                    </div>
                </div>
                <div className='item' id='mid-item-orchidea-spend-case-studies'>
                    <div className='item--image'>
                        <img src={eyetouch_illustration} alt='eyetouch_illustration' />
                    </div>
                    <div className='item--content'>
                        <h3>EyeTouch</h3>
                        <p>How a team of 2 sales reps achieve 45% increase in sales working hand by hand with us.</p>
                        <div className='highlight'><p>+60% database increase</p></div>
                    </div>
                </div>
                <div className='item' id='last-item-orchidea-leads-case-studies'>
                    <div className='item--image'>
                        <img src={edot_graf_illustration} alt='edot_graf_illustration' />
                    </div>
                    <div className='item--content'>
                        <h3>Edot Graf</h3>
                        <p>When the business model changes, the way of selling should as well.</p>
                        <div className='highlight'><p>From the very first sale</p></div>
                    </div>
                </div>
            </div>
        </div>
    );
}