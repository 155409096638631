import React from 'react';
import './PostsSection.css';
import Post from './Post/Post'; 
import CaseStudiesFilters from './Filters/CaseStudiesFilters/CaseStudiesFilters';
import load_more from './assets/load_more.svg';

export default function PostsSection(props){
    const [pageTitle] = React.useState(props.pageTitle);
    const [posts, setPosts] = React.useState([]);
    const [type] = React.useState(props.type); //must be case-study or blog: help determine the filters and selecting relevant posts from the DB
    // const [industries, setIndustries] = React.useState([]);
    // const [selectedIndustry, setSelectedIndustry] = React.useState('all');
    const page_size = 10;
    const [page, setPage] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(false);

    const fetchPosts = async () => {
        const URL = `https://17uttfecb8.execute-api.eu-west-2.amazonaws.com/Prod/posts/get-all/${page_size}?page=${page}&publish_status=published&post_type=${type}`;
        const options = {
            method: 'GET'
        }
        try{
            const response = await fetch(URL, options);
            const response_data = await response.json();
            setPosts(prev => ([...prev, ...response_data.data]));
            setHasMore(response_data.has_more)
        } catch (e){
            console.log(e);
        }
    };

    React.useEffect(() => {
        fetchPosts();
    }, [page])

    // React.useEffect(() => {
    //     // setPosts(fake_posts);
    //     if (type === 'case_studies') {
    //         let industries_from_posts = [];
    //         posts.forEach(post => industries_from_posts.push(post.industry));
    //         setIndustries([...new Set(industries_from_posts)]);
    //     }
    // }, [posts])

    // React.useEffect(() => {
    //     if(selectedIndustry === 'all'){
    //         setPosts(fake_posts);
    //     } else {
    //         const selected_posts = fake_posts.filter(post => post.industry === selectedIndustry);
    //         setPosts(selected_posts)
    //     }
    // }, [selectedIndustry])

    // const handleSetIndustry = (industry) => {
    //     setSelectedIndustry(industry);
    // }

    return (
        <div className='posts'>
            <h1>{pageTitle}</h1>
            <div className='filters'>
                {/* {type === 'case_studies' && <CaseStudiesFilters industries={industries} setIndutry={handleSetIndustry} />} */}
            </div>
            {posts && posts.length > 0 && <div className='post-list'>
                {posts.map(post => <Post key={post.id} post={post} />)}
            </div>}
            <div className='bottom-btns'>
                {hasMore && <button onClick={() => setPage(prev => prev + 1)}>
                    <img src={load_more} alt='load-more' />
                    Load more
                </button>}
            </div>
        </div>
    )
}