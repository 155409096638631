import React from 'react';
import './Post.css';
import arrow from './assets/arrow-right.svg';

export default function Post(props){
    const [post] = React.useState(props.post);
    const [isHovered, setIsHovered] = React.useState(false);

    const handleClickPost = () => {
        if(post.post_type === 'blog'){
            window.location.href = `/blog/post/${post.id}`;
        } else if (post.post_type === 'case-study'){
            window.location.href = `/case-studies/post/${post.id}`;
        }
    }

    function firstChars(str) {
        if(str.length > 64){
            return str.substring(0, 64) + '...';
        }
        return str;
      }
      

    return (
        <div 
            className='post' 
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleClickPost}
            style={{
                height: post.post_type === 'blog' ? '350px' : '250px',
                width: post.post_type === 'blog' ? '300px' : '350px',
            }}
        >
            <div className='post--top' style={{height: post.post_type === 'blog' ? '200px' : '100px'}}>
                {post.icon.endsWith('.svg') ? 
                    (<object type='image/svg+xml' data={post.icon} style={{height: post.post_type === 'blog' ? '180px' : '40px'}}>Your browser does not support svg</object>) :
                    (<img src={post.icon} alt='post-illustration' style={{height: post.post_type === 'blog' ? '180px' : '40px'}} />)
                }
            </div> 
            <div className='post-core'>
                <p className='post-overview'>{post.post_type === 'blog' ? firstChars(post.title) : post.post_type === 'case-study' ? firstChars(post.post_intro) : ''}</p>
                {/* Refactor to if post.postype === case_study  */}
                {post.post_type === 'blog' && <div className='read-more'>
                    <p>Read more</p>
                    <img src={arrow} alt='arrow' style={{display: isHovered ? 'block' : 'none'}} />
                </div>}
                {post.post_type === 'case-study' && <div className='post-case-study'>
                    <div className='cost-per-lead'>
                        <p className='figure'>£{post.cost_per_lead}</p>
                        <p className='label'>cost per lead</p>
                    </div>
                    <div className='lead-rate'>
                        <p className='figure'>{post.lead_rate}%</p>
                        <p className='label'>lead rate</p>
                    </div>
                    <div className='reponse-rate'>
                        <p className='figure'>{post.response_rate}%</p>
                        <p className='label'>response rate</p>
                    </div>
                </div>}
            </div>
        </div>
    )
}