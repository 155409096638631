import React from 'react';
import './NavbarResponsive.css';
import icon from './assets/navbar-responsive-icon.svg';
import icon_cross from './assets/x-lg.svg';


export default function NavabarResponsive(props){
    return (
        <div className='navbar-responsive' onClick={() => props.setClick(prev => !prev)} style={{background: props.buttonColor}}>
            <img src={props.isClicked ? icon_cross : icon} alt='navbar-responsive' />
        </div>
    );
}