import React from 'react';
import './EditPost.css';
import useToken from '../../Hooks/useToken';
import TextEditor from '../CreatePost/TextEditor/TextEditor';

export default function EditPost(props){
    const {token} = useToken();
    const [id] = React.useState(props.id);
    const [post, setPost] = React.useState(null);
    const [editorContent, setEditorContent] = React.useState(null);
    const [problem, setProblem] = React.useState(null);
    const [solution, setSolution] = React.useState(null);
    const [objectives, setObjectives] = React.useState(null);
    const [campaignResults, setCampaignResults] = React.useState(null);
    const fetchPost = async () => {
        const URL = `https://17uttfecb8.execute-api.eu-west-2.amazonaws.com/Prod/posts/get/${id}`;
        console.log(URL);
        const options = {
            headers: {
                Authorization: token
            },
            method: 'GET'
        }
        try{
            const response = await fetch(URL, options);
            const response_data = await response.json();
            console.log(response_data);
            setPost(response_data);
            if(response_data.post_type === 'blog'){
                setEditorContent(response_data.content)
            } else if (response_data.post_type === 'case-study'){
                setProblem(response_data.problem);
                setSolution(response_data.solution);
                setObjectives(response_data.objectives);
                setCampaignResults(response_data.campaignResults);
            }
            
        } catch (e){
            console.log(e);
            alert('Post was not find in the database!')
            window.location.reload();
        }
    }

    React.useEffect(() => {
        if(id){
            fetchPost();
        }
    }, [id])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = post;
        if(post.post_type === 'blog'){
            data.content = editorContent;
            console.log(editorContent);
        } else if (post.post_type === 'case-study'){
            data.problem = problem;
            data.solution = solution;
            data.objectives = objectives;
            data.campaignResults = campaignResults;
        }
        delete data.id;
        const URL = `https://17uttfecb8.execute-api.eu-west-2.amazonaws.com/Prod/posts/update/${id}`;
        const options = {
            headers: {
                Authorization: token
            },
            method: 'PUT',
            body: JSON.stringify(data)
        }
        try{
            const response = await fetch(URL, options);
            await response.json();
            alert('Post updated successfully!');
        } catch (e){
            console.log(e);
            alert('An error occured. The post could not be created.');
        }
        window.location.reload();
    }

    const handleDiscard = (e) => {  
        e.preventDefault();
        window.location.reload();
    }

    const handleDelete = async (e) => {
        e.preventDefault();
        var isDeleteConfirmed = window.confirm('Are you sure you want to delete this post?');
        if (isDeleteConfirmed) {
            const URL = `https://17uttfecb8.execute-api.eu-west-2.amazonaws.com/Prod/posts/delete/${id}`;
            const options = {
                headers: {
                    Authorization: token
                },
                method: 'DELETE'
            }
            try {
                const response = await fetch(URL, options);
                await response.json();
                alert('The post was successfully deleted!');
                window.location.reload();
            } catch (e) {
                alert('An error occured. The post could not be deleted.');
                console.log(e);
            }
        }
    }

    return(
        <div className='edit-post'>
            {post && post.post_type === 'blog' &&
                <form className='edit-form' onSubmit={handleSubmit}>
                    <div className='input-group'>
                        <label>Post Title</label>
                        <input required type='text' value={post.title} onChange={(e) => setPost(prev => ({...prev, title: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Post URL</label>
                        <input required type='text' value={post.id} disabled={true}/>
                    </div>
                    <div className='input-group'>
                        <label>Date</label>
                        <input required type='date' value={post.custom_date} onChange={(e) => setPost(prev => ({...prev, custom_date: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Author</label>
                        <input required type='text' value={post.author} onChange={(e) => setPost(prev => ({...prev, author: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Post Icon</label>
                        <input required type='text' value={post.icon} onChange={(e) => setPost(prev => ({...prev, icon: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Post image</label>
                        <input required type='text' value={post.image} onChange={(e) => setPost(prev => ({...prev, image: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Post preview (must be between 100-150 characters)</label>
                        <textarea required value={post.preview} onChange={(e) => setPost(prev => ({...prev, preview: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Post content</label>
                        <TextEditor setEditorContent={setEditorContent} initialValue={post.content}/>
                    </div>
                    <div className='input-group'>
                        <label>Status</label>
                        <select value={post.publish_status} onChange={(e) => setPost(prev => ({...prev, publish_status: e.target.value}))}>
                            <option value='published'>Published</option>
                            <option value='draft'>Draft</option>
                        </select>
                    </div>
                    <div className='buttons'>
                        <button type='submit' style={{marginRight: '15px'}}>Update post</button>
                        <button onClick={handleDiscard} style={{marginRight: '350px'}}>Discard</button>
                        {
                            post.publish_status === 'published' && 
                            <button onClick={handleDelete} style={{background: '#9b4b4b'}}>Delete post</button>
                        }
                    </div>
                </form>
            }
            {post && post.post_type === 'case-study' &&
                <form className='edit-form' onSubmit={handleSubmit}>
                    <div className='input-group'>
                        <label>Post Title</label>
                        <input required type='text' value={post.title} onChange={(e) => setPost(prev => ({...prev, title: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Post URL</label>
                        <input required type='text' value={post.id} disabled={true}/>
                    </div>
                    <div className='input-group'>
                        <label>Service</label>
                        <select value={post.service}  onChange={(e) => setPost(prev => ({...prev, service: e.target.value}))}>
                            <option value='leads'>OrchideaLeads</option>
                            <option value='hire'>OrchideaHire</option>
                        </select>
                    </div>
                    <div className='input-group'>
                        <label>Date</label>
                        <input required type='date' value={post.custom_date} onChange={(e) => setPost(prev => ({...prev, custom_date: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Post Icon</label>
                        <input required type='text' value={post.icon} onChange={(e) => setPost(prev => ({...prev, icon: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Main image</label>
                        <input required type='text' value={post.image} onChange={(e) => setPost(prev => ({...prev, image: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Introduction (must be between 100-150 characters)</label>
                        <textarea required value={post.post_intro} onChange={(e) => setPost(prev => ({...prev, post_intro: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Company Industry</label>
                        <select value={post.company_industry} onChange={(e) => setPost(prev => ({...prev, company_industry: e.target.value}))}>
                            <option value='Agriculture and Forestry'>Agriculture and Forestry</option>
                            <option value='Automotive and Transportation'>Automotive and Transportation</option>
                            <option value='Construction and Real Estate'>Construction and Real Estate</option>
                            <option value='Consumer Goods and Retail'>Consumer Goods and Retail</option>
                            <option value='Energy and Utilities'>Energy and Utilities</option>
                            <option value='Financial Services'>Financial Services</option>
                            <option value='Information Technology and Software'>Information Technology and Software</option>
                            <option value='Manufacturing and Heavy Industries'>Manufacturing and Heavy Industries</option>
                            <option value='Mining and Metals'>Mining and Metals</option>
                            <option value='Entertainment, Media and Publishing'>Entertainment, Media and Publishing</option>
                            <option value='Telecommunications'>Telecommunications</option>
                            <option value='Travel, Hospitality and Food Services'>Travel, Hospitality and Food Services</option>
                            <option value='Education and Training'>Education and Training</option>
                            <option value='Professional Services'>Professional Services</option>
                            <option value='Government and Public Services'>Government and Public Services</option>
                            <option value='Non-Profit and Social Services'>Non-Profit and Social Services</option>
                            <option value='Fashion and Apparel'>Fashion and Apparel</option>
                            <option value='E-commerce and Digital Services'>E-commerce and Digital Services</option>
                            <option value='Environmental and Sustainability'>Environmental and Sustainability</option>
                        </select>
                    </div>
                    <div className='input-group'>
                        <label>Company Website</label>
                        <input type='text' value={post.company_website} onChange={(e) => setPost(prev => ({...prev, company_website: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Company profile</label>
                        <textarea value={post.company_profile} onChange={(e) => setPost(prev => ({...prev, company_profile: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Company target profile</label>
                        <textarea value={post.company_target_profile} onChange={(e) => setPost(prev => ({...prev, company_target_profile: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Company description</label>
                        <textarea value={post.company_description} onChange={(e) => setPost(prev => ({...prev, company_description: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Cost per lead / candidate (in £)</label>
                        <input type='text' value={post.cost_per_lead} onChange={(e) => setPost(prev => ({...prev, cost_per_lead: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Lead / Candiate rate (in %)</label>
                        <input type='text' value={post.lead_rate} onChange={(e) => setPost(prev => ({...prev, lead_rate: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Open rate (in %)</label>
                        <input type='text' value={post.open_rate} onChange={(e) => setPost(prev => ({...prev, open_rate: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Response rate (in %)</label>
                        <input type='text' value={post.response_rate} onChange={(e) => setPost(prev => ({...prev, response_rate: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Number of channels used</label>
                        <select value={post.channels} onChange={(e) => setPost(prev => ({...prev, channels: e.target.value}))}>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                        </select>
                    </div>
                    <div className='input-group'>
                        <label>Number of prospects engaged</label>
                        <input type='text' value={post.prospects_engaged} onChange={(e) => setPost(prev => ({...prev, prospects_engaged: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Total additional traffic / connections?</label>
                        <input type='text' value={post.additional_traffic} onChange={(e) => setPost(prev => ({...prev, additional_traffic: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Problem</label>
                        <TextEditor setEditorContent={setProblem} initialValue={problem}/>
                    </div>
                    <div className='input-group'>
                        <label>Our Solution</label>
                        <TextEditor setEditorContent={setSolution} initialValue={solution} />
                    </div>
                    <div className='input-group'>
                        <label>Objectives</label>
                        <TextEditor setEditorContent={setObjectives} initialValue={objectives}/>
                    </div>
                    <div className='input-group'>
                        <label>Campaign results</label>
                        <TextEditor setEditorContent={setCampaignResults} initialValue={campaignResults} />
                    </div>
                    <div className='input-group'>
                        <label>Status</label>
                        <select value={post.publish_status} onChange={(e) => setPost(prev => ({...prev, publish_status: e.target.value}))}>
                            <option value='published'>Published</option>
                            <option value='draft'>Draft</option>
                        </select>
                    </div>
                    <div className='buttons'>
                        <button type='submit' style={{marginRight: '15px'}}>Update post</button>
                        <button onClick={handleDiscard} style={{marginRight: '360px'}}>Discard</button>
                        {
                            post.publish_status === 'published' && 
                            <button onClick={handleDelete} style={{background: '#9b4b4b'}}>Delete post</button>
                        }
                    </div>
                </form>
            }
        </div>
    );
}