import React from 'react';
import './ContactPage.css';
import Navbar from '../../Navbar/Navbar.js';
import ContactMainSection from './ContactMainSection/ContactMainSection';
import ContactProcess from './ContactProcess/ContactProcess';
import Footer from '../../Footer/Footer';

export default function ContactPage(){
    return (
        <div className='contact-page'>
            <Navbar buttonColor='#d8f7ee' buttonColorHover='#d9f3ec' colorText='#25262B' />
            <ContactMainSection />
            <ContactProcess />
            <Footer />
        </div>
    );
}