import React from 'react';
import './AboutOwnCaseStudy.css';
import photo from './assets/photo-group.svg';

export default function AboutOwnCaseStudy(){
    return (
        <div className='about-own-case-study'>
            <div className='content'>
                <h2>We're our own best case study</h2>
                <img src={photo} alt='people-group-orchidea' />
                <div className='highlighted-text'>
                    <h2>70% of our new business</h2>
                    <p>comes from our own email marketing campaigns.</p>
                </div>
                <p>Get in touch to find out why our methods illicit real results.</p>
                <div className='buttons'>
                    <button onClick={() => window.location.href = '/contact'}>Let's Bloom</button>
                    <button className='learn-more' onClick={() => window.location.href = '/pricing'}>See pricing</button>
                </div>
            </div>
            <img src={photo} alt='grouo-people-orchidea'/>
        </div>
    );
}