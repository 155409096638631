import React, { useState, useEffect } from 'react';

const steps = [1, 2, 3, 4, 5, 6, 8, 10, 12];
const min = 0;
const max = steps.length - 1;

export default function SalesCycleSlider(props) {
    const [sliderValue, setSliderValue] = useState(min);

    useEffect(() => {
        if (sliderValue < min) {
            setSliderValue(min);
        } else if (sliderValue > max) {
            setSliderValue(max);
        }
        props.setSalesCycle(sliderValue);
    }, [sliderValue, props]);

    const handleChange = (e) => {
        setSliderValue(e.target.value);
    };

    const percentage = (sliderValue / max) * 100;

    return (
    <div className='input-range-container'>
        <input type='range' min={min} value={sliderValue} max={max} step='1' onChange={handleChange} />
        <div className='selector-input-range' style={{left: `${percentage}%`}}>
            <div className='SelectBtn-input-range'></div>
        </div>
        <div className="progress-bar-input-range" style={{width: `${percentage}%`}}></div>
    </div>
    );
};
