import React from 'react';
import './NavbarMenu.css';
import NavbarItem from './NavbarItem/NavbarItem';
import NavbarButton from './NavbarButton/NavbarButton';
import NavbarDropdown from './NavbarDropdown/NavbarDropdown';

export default function NavbarMenu(props){
    const [toBeDisplayed, setToBeDisplayed] = React.useState(true);

    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 800 || props.display) {
                setToBeDisplayed(true);
            } else {
                setToBeDisplayed(false);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
    }, [props.display]);

    return(
        <div className="menu" style={{display: toBeDisplayed ? 'flex' : 'none'}}>
            <div className="menu-items">
                <NavbarDropdown label='Services'>
                    <NavbarItem to='/leads' label='OrchideaLeads' />
                    <NavbarItem to='/hire' label='OrchideaHire' />
                    <NavbarItem to='/spend' label='OrchideaSpend' />
                </NavbarDropdown>
                <NavbarItem to='/pricing' label='Pricing' />
                <NavbarDropdown label='Resources'>
                    <NavbarItem to='/case-studies' label='Case Studies' />
                    <NavbarItem to='/blog' label='Blog posts' />
                    {/* <NavbarItem to='' label='Videos' />
                    <NavbarItem to='' label='Events' />
                    <NavbarItem to='' label='Guides' /> */}
                </NavbarDropdown>
                <NavbarItem to='/about' label='About' />
                <NavbarItem to='/contact' label='Contact' />
            </div>
            <NavbarButton buttonColor={props.buttonColor} buttonColorHover={props.buttonColorHover} colorText={props.colorText} />
        </div>
    )
}