import React from 'react';
import './IntroHomePage.css';
import illustration from './assets/illustration_intro_home_page.svg';
import illustration_responsive from './assets/illustration_intro_home_page_responsive.svg';

export default function IntroHomePage(){

    return (
        <div className='intro-home-page'>
            <img className='intro-home-page--illustration' src={illustration} alt='illustration-orchidea' />
            <div className='intro-home-page--content'>
                <h1>Accelerate & <br /> Outsource Organic <br /> Company Growth</h1>
                <img className='intro-home-page--illustration-responsive' src={illustration_responsive} alt='illustration-orchidea' />
                <p> 
                    <span>Orchidea</span> helps companies maximise and <br />
                    manage lead generation, PPC & recruit for the perfect fit. <br />
                <span>Saving you time, resources, and delivering results.</span>
                </p>
                <button onClick={() => window.location.href = '/contact'}>Book a Call</button>
            </div>
            <img className='intro-home-page--illustration-large' src={illustration_responsive} alt='illustration-orchidea' />
        </div>
    );
}