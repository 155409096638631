import React from 'react';
import './Login.css';
import login_illustration from './assets/login-illustration.svg';
import back_button_icon from './assets/arrow-left.svg';
import back_button_icon_short from './assets/arrow-left-short.svg';

export default function Login(props) {

    const [error, setError] = React.useState(null);

    const [buttonImg, setButtonImg] = React.useState(back_button_icon_short);

    const handleLogin = (e) => {
        e.preventDefault();
        setError(null);
        setTimeout(() => {
            setError('Invalid credentials')
          }, 1000);
    }

    return (
        <div className="login">
            <div className='login--top-bar'>
                <button 
                    onMouseEnter={() => setButtonImg(back_button_icon)} 
                    onMouseLeave={() => setButtonImg(back_button_icon_short)}
                    onClick={() => window.location.href = '/'}
                >
                    <img src={buttonImg} alt='back'/>
                    Back
                </button> 
            </div>
            <div className='login--inside'>
                <div className="login--illustration">
                    <img src={login_illustration} alt="login illustration" />
                </div>
                <div className="login--form">
                    <h1>Sign in</h1>
                    <p>Orchidea's Client Portal</p>
                    <div className='login--form-inputs'>
                        <form onSubmit={handleLogin} className='from--login'>
                            <input type="text" required placeholder="Email Address" />
                            <input type="password" required placeholder="Password" />
                            <button type='submit'>Login</button>
                        </form>
                        {error && <div className='error'>
                            <p>{error}</p>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}