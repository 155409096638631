import React from 'react';
import './OrchideaSpendWidget.css';
import linkedin_illustration from './assets/linkedin.svg';
import google_illustration from './assets/google_ads.png';
import seo_illustration from './assets/seo.svg';
import social_illustration from './assets/all_social_media.svg';
import linkedin_icon from './assets/icons/linkedin.svg';
import seo_icon from './assets/icons/seo.svg';
import google_icon from './assets/icons/google.svg';
import social_icon from './assets/icons/social.svg';
import star_icon from './assets/star.svg';
import arrow_right from './assets/arrow-right-circle.svg';
import arrow_left from './assets/arrow-left-circle.svg';

export default function OrchideaSpendWidget(props){
    const [active, setActive] = React.useState('seo');

    const collection = ['seo', 'google', 'linkedin', 'social'];

    const illustrations = {
        'seo': seo_illustration,
        'google': google_illustration,
        'linkedin': linkedin_illustration,
        'social': social_illustration
    }

    const textContent = {
        'seo': {
            title: 'SEO Strategy & Report',
            content: <div>
                    <p>Improve your online visibility and attract new customers with our SEO strategy and audit service.</p>
                    <p>Our team will conduct a comprehensive audit of your website(s), develop a customized SEO strategy, and implement tactics like keyword research, on-page optimization, link building, and content creation to improve your search engine ranking.</p> 
                    <p><span style={{color: '#AD9FFD'}}>All in a comprehensible deliverable.</span></p>
                </div>
        },
        'google': {
            title: 'Google Ads',
            content: <div>
                <p>Improve your online visibility and attract new customers with our SEO strategy and audit service.</p>
                <p>Our team will conduct a comprehensive audit of your website(s), develop a customized SEO strategy, and implement tactics like keyword research, on-page optimization, link building, and content creation to improve your search engine ranking.</p>
                <p><span style={{color: '#AD9FFD'}}>All in a comprehensible deliverable.</span></p>
            </div>
        },
        'linkedin': {
            title: 'LinkedIn',
            content: <div>
                <p><span>LinkedIn is by far the best channel</span> for ultra qualified, hyper targetted campaigns on professionals to either become a client or an employee.</p>
                <p><span>Our team of experienced professionals will create compelling ads that capture your target audience's</span> attention and drive them to a lead form, allowing you to collect valuable and measurable insights, and <span>results</span>.</p>
                <p><span>We will set up</span> and manage your entire Linkedin paid campaigns including audience & ads to <span>optimise your PPC!</span></p>
            </div>
        },
        'social': {
            title: 'All Social Media',
            content: <div>
                <p>Have other social medias? We're flexible and savy! Maximise your advertising results with and let us take over.</p>
                <p>Our team of experienced professionals will develop and manage your social media advertising campaigns, optimizing your targeting, ad creatives, and bidding strategies to ensure maximum ROI.</p>
            </div>
        }
    }

    function handleClickImage() {
        var image = document.getElementById('widget-illustration-main');
        console.log(image.src);
        image.classList.remove("img-zoom-in");
        void image.offsetWidth; // force reflow
        image.classList.add("img-zoom-in");
    }

    function handleClickNext(){
        const index = collection.indexOf(active);
        const newIndex = (index + 1 === collection.length) ? 0 : index + 1;
        setActive(collection[newIndex]);
    }
    
    function handleClickPrevious(){
        const index = collection.indexOf(active);
        const newIndex = (index - 1 < 0 ) ? (collection.length - 1) : index - 1;
        setActive(collection[newIndex]);
    }
    
    return (
        <div className='orchidea-spend-widget'>
            <h1>Orchidea<span>Spend</span></h1>
            <div className='orchidea-spend-widget--widget'>
                <div className='widget--nav'>
                    <div className='box'>
                        <img src={star_icon} alt='star' className='star-icon'/>
                        <div className='large-item item'  onClick={() => {setActive('social'); handleClickImage()}}>
                            <div className='logo-container' style={{backgroundColor: active === 'social' ? '#C7C0FC' : '#C6C7CC'}}>
                                <img src={social_icon} alt='all-social-icon'/>
                            </div>
                            <div className='text-container' style={{justifyContent: 'flex-start'}}>
                                <p style={{marginLeft: '40px'}}>All Social Media</p>
                            </div>
                        </div>
                    </div>
                    <div className='box' style={{borderTop: 'none', borderBottom: 'none'}}>
                        <img src={star_icon} alt='star' className='star-icon'/>
                        <div className='item' style={{top: '-35px'}} onClick={() => {setActive('google'); handleClickImage()}}>
                            <div className='logo-container' style={{backgroundColor: active === 'google' ? '#C7C0FC' : '#C6C7CC'}}>
                                <img src={google_icon} alt='google-leads-icon'/>
                            </div>
                            <div className='text-container'>
                                <p>Google Ads</p>
                            </div>
                        </div>
                    </div>
                    <div className='box' style={{borderBottomRightRadius: '20px'}}>
                        <img src={star_icon} alt='star' className='star-icon'/>
                        <div className='item' style={{top: '-35px'}} onClick={() => {setActive('linkedin'); handleClickImage()}}>
                            <div className='logo-container' style={{backgroundColor: active === 'linkedin' ? '#C7C0FC' : '#C6C7CC'}}>
                                <img src={linkedin_icon} alt='linkedin-icon'/>
                            </div>
                            <div className='text-container'>
                                <p>LinkedIn</p>
                            </div>
                        </div>
                        <div className='item' style={{bottom: '-35px'}} onClick={() => {setActive('seo'); handleClickImage()}}>
                            <div className='logo-container' style={{backgroundColor: active === 'seo' ? '#C7C0FC' : '#C6C7CC'}} >
                                <img src={seo_icon} alt='seo-icon'/>
                            </div>
                            <div className='text-container'>
                                <p>SEO Strategy & Report</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='widget-content'>
                    <img alt='arrow-left' src={arrow_left} id='widget-arrow-left' onClick={handleClickPrevious}/>
                    <div className='widget-illustration'>
                        <img src={illustrations[active]} alt='illustration-widget' id='widget-illustration-main' />
                    </div>
                    <div className='widget-text-content'>
                        <h2>{textContent[active].title}</h2>
                        {textContent[active].content}
                    </div>
                    <img alt='arrow-right' src={arrow_right} id='widget-arrow-right' onClick={handleClickNext} />
                </div>
            </div>
            <div className="orchidea-spend-widget-buttons">
                    <button onClick={() => window.location.href = '/contact'}>Let's Bloom</button>
                    <button className="learn-more" onClick={props.goToPricingRef}>See pricing</button>
                </div>
        </div>
    );
}