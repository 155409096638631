import React from 'react';
import './OrchideaSpendPricing.css';
import seo_illustration from './assets/seo.svg';
import google_ads_illustration from './assets/google_ads.png';
import linkedin_illustration from './assets/linkedin.svg';
import social_media_illustration from './assets/social_media.svg';
import check from './assets/check-circle-fill.svg';
import check_colored from './assets/check-circle-fill-colored.svg';

export default function OrchideaSpendPricing(props){
    const [bgBtn, setBgBtn] = React.useState('rgb(127, 99, 255)');
    const [displayTitle] = React.useState(props.displayTitle === false ? false : true)

    return (
        <div 
            className='orchidea-spend-pricing' 
            style={{paddingTop: props.paddingTopIn ? props.paddingTopIn : '70px'}}
            ref={props.pricingRef}
        >
            {displayTitle && <h1>Our Pricing</h1>}
            <div className='prices'>
                <div className='price'>
                    <div className='title'>
                        <h2>SEO</h2>
                        <p className='starting-from'>Starting from <span>£1,500</span></p>
                    </div>
                    <img src={seo_illustration} alt='seo-pricing' />
                    <div className='benefits'>
                        <div className='benefit'>
                            <img src={check} alt='check-mark' />
                            <p>Full SEO Audit report</p>
                        </div>
                        <div className='benefit'>
                            <img src={check} alt='check-mark' />
                            <p>Personalised Optimisation strategy</p>
                        </div>
                    </div>
                    <button onClick={() => window.location.href = '/contact'}>Book a call</button>
                </div>
                <div className='price'>
                    <div className='title'>
                        <h2>Google Ads</h2>
                        <p className='starting-from'>Starting from <span>£1,500</span></p>
                    </div>
                    <img src={google_ads_illustration} alt='seo-pricing' />
                    <div className='benefits'>
                        <div className='benefit'>
                            <img src={check} alt='check-mark' />
                            <p>Full Google Ads set up</p>
                        </div>
                        
                        <div className='benefit'>
                            <img src={check} alt='check-mark' />
                            <p>Campaign management & reporting</p>
                        </div>
                    </div>
                    <button onClick={() => window.location.href = '/contact'}>Book a call</button>
                </div>
                <div className='price price-large' style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'}}>
                    <div className='title'>
                        <h2 style={{color: 'rgb(127, 99, 255)'}}>Social Media</h2>
                        <p className='starting-from'>Starting from <span style={{color: 'rgb(127, 99, 255)'}}>£5,000</span></p>
                    </div>
                    <img src={social_media_illustration} alt='seo-pricing' />
                    <div className='benefits'>
                        <div className='benefit'>
                            <img src={check_colored} alt='check-mark' />
                            <p>Ecosystem management</p>
                        </div>
                        <div className='benefit'>
                            <img src={check_colored} alt='check-mark' />
                            <p>Campaign setup, management and optimization</p>
                        </div>
                        <div className='benefit'>
                            <img src={check_colored} alt='check-mark' />
                            <p>Comprehensive reporting</p>
                        </div>
                    </div>
                    <button 
                        style={{backgroundColor: bgBtn}}
                        onMouseLeave={() => setBgBtn('rgb(127, 99, 255)')}
                        onMouseEnter={() => setBgBtn('#957EFF')}
                        onClick={() => window.location.href = '/contact'}
                    >
                            Book a call
                    </button>
                </div>
                <div className='price'>
                    <div className='title'>
                        <h2>LinkedIn</h2>
                        <p className='starting-from'>Starting from <span>£1,900</span></p>
                    </div>
                    <img src={linkedin_illustration} alt='linkedin-pricing' />
                    <div className='benefits'>
                        <div className='benefit'>
                            <img src={check} alt='check-mark' />
                            <p>Full LinkedIn Ads set up</p>
                        </div>
                        <div className='benefit'>
                            <img src={check} alt='check-mark' />
                            <p>Campaign management & reporting</p>
                        </div>
                    </div>
                    <button onClick={() => window.location.href = '/contact'}>Book a call</button>
                </div>
            </div>
        </div>
    )
}