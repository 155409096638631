import React from 'react';
import './AboutWeAreSection.css';
import we_are_illustration from './assets/we-are-logo.svg';

export default function AboutWeAreSection(){
    return (
        <div className='we-are'>
            <p>We are</p>
            <img src={we_are_illustration} alt='we-are-orchidea' />
            <p>Orchidea</p>
        </div>
    );
}