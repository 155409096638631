import React from 'react';
import './PostSuggestions.css';
import Post from '../PostsSection/Post/Post';


export default function PostSuggestions(props){
    const [posts, setPosts] = React.useState([]);
    const [postsToDisplay, setPostsToDisplay] = React.useState([]);
    const [type] = React.useState(props.type); //must be case-study or blog: help determine the filters and selecting relevant posts from the DB
    const [title] = React.useState(props.title);

    const fetchPosts = async () => {
        const URL = `https://17uttfecb8.execute-api.eu-west-2.amazonaws.com/Prod/posts/get-all/3?page=1&publish_status=published&post_type=${type}`;
        const options = {
            method: 'GET'
        }
        try{
            const response = await fetch(URL, options);
            const response_data = await response.json();
            setPosts(response_data.data.slice(0, 3));
        } catch (e){
            console.log(e);
        }
    };

    React.useEffect(() => {
        fetchPosts();
    }, [])

    React.useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 1150) {
            setPostsToDisplay(posts.slice(0, 2));
          } else {
            setPostsToDisplay(posts);
          }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, [posts]);

    React.useEffect(() => {
        setPostsToDisplay(posts);
    }, [posts])

    if(posts && posts.length > 0){
        return (
            <div className='post-suggestions'>
                <p className='title'>{title}</p>
                <div className='post-list'>
                    {postsToDisplay.map(post => <Post key={post.id} post={post} />)}
                </div>
            </div>
        )
    }

    return (<div></div>)
}