import React from 'react';
import './PartnersHomePage.css';
import partners_image from './assets/partners_logos.svg'

export default function PartnersHomePage(){
    return (
        <div className='partners-home-page--container'>
            <div className="partners-home-page">
                <img src={partners_image} alt="partners-orchidea" />
            </div>
        </div>
    );
}