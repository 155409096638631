import React from 'react';
import './NavbarButton.css';

export default function NavbarButton(props){
    const [background, setBackground] = React.useState(props.buttonColor);

    return (
        <div className="navbar--button">
            <button 
                style={{background:background, color: props.colorText}} 
                onMouseEnter={() => setBackground(props.buttonColorHover)}
                onMouseLeave={() => setBackground(props.buttonColor)}
                onClick={() => window.location.href = '/contact'}
            >
                Book a Call
            </button>
            <button 
                className='log-in-button'
                onClick={() => window.location.href = '/log-in' }
            >
                Log in
            </button>
        </div>
    )
}