import React from 'react';
import './ResourceBanner.css';

export default function ResourceBanner(props){
    const title = props.title;
    const subtitle = props.subtitle;
    const image = props.image;

    return (
        <div className='resource-banner'>
            <div className='img-container'>
                <img src={image} alt='resource-banner-img' />
            </div>
            <div className='text'>
                <h1 className='title'>{title}</h1>
                <h3 className='subtitle'>{subtitle}</h3>
            </div>
        </div>
    );
}