import React from 'react';
import './OrchideaSpendPage.css';
import Navbar from '../../Navbar/Navbar';
import IntroOrchideaSpendPage from './IntroOrchideaSpendPage/IntroOrchideaSpendPage';
import BannerOrchideaSpendPage from './BannerOrchideaSpendPage/BannerOrchideaSpendPage';
import OrchideaSpendWidget from './OrchideaSpendWidget/OrchideaSpendWidget';
import BoostOrchideaSpend from './BoostOrchideaSpend/BoostOrchideaSpend';
import CaseStudiesOrchideaSpend from './CaseStudiesOrchideaSpend/CaseStudiesOrchideaSpend';
import OrchideaSpendCounter from './OrchideaSpendCounter/OrchideaSpendCounter';
import LetsBloomSpendPage from './LetsBloomSpendPage/LetsBloomSpendPage';
import Footer from '../../Footer/Footer';
import OrchideaSpendPricing from './OrchideaSpendPricing/OrchideaSpendPricing';
import OrchideaSpendWithWithout from './OrchideaSpendWithWithout/OrchideaSpendWithWithout';

export default function OrchideaSpendPage(){
    const pricingRef = React.useRef(null);

    const goToPricingRef = () => {
        if (pricingRef.current) {
            pricingRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='orchidea-spend-page'>
            <Navbar buttonColor='#E8E4FF' buttonColorHover='#EEEBFD' colorText='#7F63FF' />
            <IntroOrchideaSpendPage goToPricingRef={goToPricingRef} />
            <OrchideaSpendWithWithout />
            <BannerOrchideaSpendPage />
            <OrchideaSpendWidget goToPricingRef={goToPricingRef}/>
            <BoostOrchideaSpend />
            <OrchideaSpendPricing pricingRef={pricingRef}/>
            <CaseStudiesOrchideaSpend />
            <OrchideaSpendCounter />
            <LetsBloomSpendPage />
            <Footer />
        </div>
    )
}