import React from 'react';
import './Navbar.css'
import NavbarLogo from './NavbarLogo/NavbarLogo';
import NavabarResponsive from './NavbarResponsive/NavbarResponsive';
import NavbarMenu from './NavbarMenu/NavbarMenu';

export default function Navbar(props){
    const [isResponsiveClicked, setIsResponsiveClicked] = React.useState(false);

    return(
        <div className='navbar-container'>
            <div className="navbar">
                <NavbarLogo />
                <NavbarMenu display={isResponsiveClicked} buttonColor={props.buttonColor} buttonColorHover={props.buttonColorHover} colorText={props.colorText}/>
                <NavabarResponsive isClicked={isResponsiveClicked} setClick={setIsResponsiveClicked} buttonColor={props.buttonColor}/>
            </div>
        </div>
    );

}