import React from 'react';
import './AboutDescriptionEnd.css';

export default function AboutDescriptionEnd(){
    return (
        <div className='about-description-end'>
            <div className="banner-text">
                <p>Why do companies look to us as a leading business growth Accelerator?</p>
            </div>
            <div className='about-description--inside'>
                <p style={{marginTop: '40px'}}>We are a UK based but serving clients all over world, to generate leads and candidates, saving time, money and effort. It is  easier, quicker and better outcomes to outsource with Orchidea rather than deal with the hassle yourself!</p>
            </div>
        </div>
    );
}