import React from 'react';
import './BrandsHomePage.css';
import abbott_logo from './assets/Abbott.svg';
import deloitte_logo from './assets/Deloitte.svg';
import google_logo from './assets/Google.svg';
import imperial_logo from './assets/Imperial.svg';
import linkedin_logo from './assets/Linkedin.svg';
import microsoft_logo from './assets/microsoft.svg';
import teva_logo from './assets/Teva.svg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default function BrandsHomePage(){
    var settings = {
        accessibility: false,
        draggable: false,
        touchMove: false,
        dots: false,
        infinite: true,
        slidesToShow:5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 100,
        cssEase: "linear",
        arrows: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
      };

    return (
        <div className="brands-home-page">
            <div className="title">
                <h2>Helped Growth of</h2>
            </div>
            <div className='brands-home-page--slider-container'>
                <Slider {...settings}>
                    <div className='logo-container'>
                        <img src={microsoft_logo} alt="microsoft-logo"  />
                    </div>
                    <div className='logo-container'>
                        <img src={abbott_logo} alt="abbott-logo"  />
                    </div>
                    <div className='logo-container'>
                        <img src={imperial_logo} alt="imperial-logo"  />
                    </div>
                    <div className='logo-container'>
                        <img src={teva_logo} alt="teva-logo"  />
                    </div>
                    <div className='logo-container'>
                        <img src={google_logo} alt="google-logo" />
                    </div>
                    <div className='logo-container'>
                        <img src={deloitte_logo} alt="deloitte-logo"  />
                    </div>
                    <div className='logo-container'>
                        <img src={linkedin_logo} alt="linkedin-logo"  />
                    </div>
                </Slider>
            </div>
        </div>
    );
}