import React from 'react';
import './OrchideaHireIntro.css';
import orchidea_hire_intro_illustration from './assets/orchidea-hire-intro.svg';

export default function OrchideaHireIntro(){
    return (
        <div className="orchidea-hire-intro">
            <div className="orchidea-hire-intro-content">
                <h1>Orchidea<span>Hire</span></h1>
                <img src={orchidea_hire_intro_illustration} alt="orchidea-hire" className='illustration-responsive' />
                <p>Recruiting for the right fit can be difficult, particularly when those you are looking for either do not see your ad, may be unaware of your company or opportunity, or may not even be applying at all in the time you are hiring.</p>
                <p><span>Orchidea helps actively headhunt for the right applicants utilising multiple databases, university partnerships, and professional contact lists.</span></p>
                <p>We scout them, collect CVs, screen them and hand the right candidates over when they are ready to learn more.</p>
                <div className="orchidea-hire-intro-buttons">
                    <button onClick={() => window.location.href = '/contact'}>Book a Call</button>
                    <button className="learn-more" onClick={() => window.location.href = '/hire'}>Learn more</button>
                </div>
            </div>
            <img src={orchidea_hire_intro_illustration} alt="orchidea-hire" />
        </div>
    );
}