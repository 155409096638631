import { useState } from 'react';

export default function useUser() {
  const getUser = () => {
    const userString = sessionStorage.getItem('user');
    return userString ? JSON.parse(userString) : null;
  };

  const [user, setUser] = useState(getUser());

  const saveUser = userObject => {
    sessionStorage.setItem('user', JSON.stringify(userObject));
    setUser(userObject);
  };

  return {
    setUser: saveUser,
    user
  }
}