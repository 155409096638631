import React from 'react';
import './CalendarContainer.css';
import { InlineWidget } from 'react-calendly';

export default function CalendarContainer(){
    const calendly_link = 'https://calendly.com/d/2jv-dd3-tgh/30-minute-meeting';
    return (
        <div className='calendar-container'>
            <h2>Let's Bloom</h2>
            <div className='calendar-wrapper'>
                <button onClick={() => window.location.href = calendly_link}>Book a demo via our calendar</button>
                <div className='calendar-box'>
                    <InlineWidget styles={{height: '420px'}} url='https://calendly.com/orchidea-io/30min?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=003c33' />
                </div>
            </div>
        </div>
    );
}