import React from 'react';
import './ContactProcess.css';
import step1 from './assets/step1.svg';
import step2 from './assets/step2.svg';
import step3 from './assets/step3.svg';
import step4 from './assets/step4.svg';
import step5 from './assets/step5.svg';
import arrow from './assets/arrow.svg';

export default function ContactProcess(){
    const [days, setDays] = React.useState({
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
    });

    function getCurrentTime() {
        const d = new Date();
        return d.toLocaleString('en-US', { month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(' at ', ', ');
    }

    function addWorkingDays(workingDays) {
        let currentDate = new Date();
        for(let i = 0; i < workingDays; ) {
            currentDate.setDate(currentDate.getDate() + 1);  // Move to the next day
    
            if(currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {  // Skip Sunday (0) and Saturday (6)
                i++;
            }
        }
        currentDate.setHours(14, 0, 0, 0);        
        return currentDate.toLocaleString('en-US', { month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(' at ', ', ');
    }

    React.useEffect(() => {
        setDays(prev => ({
            1: getCurrentTime(),
            2: addWorkingDays(3),
            3: addWorkingDays(7),
            4: addWorkingDays(14),
            5: addWorkingDays(21)
        }));
    }, [])

    return (
        <div className='contact-process'>
            <h2><span>What's Next?</span></h2>
            <div className='process-container'>
                <div className='items'>
                    <div className='item'>
                        <img src={step1} alt='step1' className='illustration' />
                        <p>You are here</p>
                        <div>
                            <p>{days[1]}</p>
                        </div>
                        <img className='arrow' src={arrow} alt='arrow' />
                    </div>
                    <div className='item'>
                        <img src={step2} alt='step2' className='illustration' />
                        <p>Quick chat and demo</p>
                        <div>
                            <p>{days[2]}</p>
                        </div>
                        <img className='arrow' src={arrow} alt='arrow' />
                    </div>
                    <div className='item'>
                        <img src={step3} alt='step3' className='illustration' />
                        <p>Proposal with forecasted outcomes & pricing</p>
                        <div>
                            <p>{days[3]}</p>
                        </div>
                        <img className='arrow' src={arrow} alt='arrow' />
                    </div>
                    <div className='item'>
                        <img src={step4} alt='step4' className='illustration' />
                        <p>Onboarding</p>
                        <div>
                            <p>{days[4]}</p>
                        </div>
                        <img className='arrow' src={arrow} alt='arrow' />
                    </div>
                    <div className='item'>
                        <img src={step5} alt='step5' className='illustration' />
                        <p>Let's Bloom</p>
                        <div>
                            <p>{days[5]}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='buttons'>
                <button onClick={() => window.location.href = '/about'}>Learn more about us</button>
                <button className='learn-more'>See pricing</button>
            </div>
        </div>
    );
}