import React from 'react';
import './OrchideaLeadsForecast.css';
import funnel_illustration from './assets/funnel3.svg';
import orchidea_logo_funnel from './assets/orchidea-logo-funnel.svg';
import MonthlyBudgetSlider from './MonthlyBudgetSlider/MonthlyBudgetSlider';
import SalesCycleSlider from './SalesCycleSlider/SalesCycleSlider';

const conversionRateData = [
    { range: [1, 999], percentages: [0.05, 0.05, 0.05, 0.045, 0.045, 0.045, 0.04, 0.04, 0.035] },
    { range: [1000, 14999], percentages: [0.045, 0.04, 0.035, 0.035, 0.035, 0.035, 0.035, 0.035, 0.03] },
    { range: [15000, 49999], percentages: [0.04, 0.035, 0.035, 0.035, 0.035, 0.035, 0.03, 0.03, 0.03] },
    { range: [50000, 499999], percentages: [0.035, 0.035, 0.035, 0.035, 0.035, 0.03, 0.03, 0.03, 0.025] },
    { range: [500000, Infinity], percentages: [0.03, 0.03, 0.03, 0.025, 0.025, 0.025, 0.02, 0.02, 0.02] },
];

const salesCycleData = [1, 2, 3, 4, 5, 6, 8, 10, 12];
  
function getPercentage(a, b) {
    const row = conversionRateData.find((row) => a >= row.range[0] && a <= row.range[1]);
    if (!row) {
        throw new Error('Invalid value for Average Deal Value.');
    }
    if (b === -1) {
        throw new Error('Invalid value for Average Sales Cycle.');
    }
    return row.percentages[b];
}
  
const conversionRateProposals = 0.3;
const conversionRateDeals = 0.21;  

const budget_to_prospects = {
    '£':  {
        1500: 1500,
        2000: 2500,
        3500: 5000,
        5000: 10000,
        8000: 22000,
        11000: 45000
    },
    '€': {
        1800: 1500,
        2400: 2500,
        4200: 5000,
        6000: 10000,
        9600: 22000,
        13200: 45000
    },
    '$': {
        1950: 1500,
        2600: 2500,
        4550: 5000,
        6500: 10000,
        10400: 22000,
        14300: 45000
    }
}

const steps = {
    '£': [1500, 2000, 3500, 5000, 8000, 11000],
    '€': [1800, 2400, 4200, 6000, 9600, 13200],
    '$': [1950, 2600, 4550, 6500, 10400, 14300],
}

export default function OrchideaLeadsForecast(props){
    const [monthlyBudget, setMonthlyBudget] = React.useState(2000);
    const [salesCycle, setSalesCycle] = React.useState(3);
    const [dealValue, setDealValue] = React.useState(1000);
    const [prospect, setProspects] = React.useState('0');
    const [opens, setOpens] = React.useState('0');
    const [responses, setReponses] = React.useState('0');
    const [conversionRateLeads, setConversionRateLeads] = React.useState(3.5);
    const [leads, setLeads] = React.useState(0)
    const [proposals, setProposals] = React.useState(0);
    const [deals, setDeals] = React.useState(0);
    const [returnI, setReturnI] = React.useState(0);
    const [currency, setCurrency] = React.useState('£');
    const [displayText, setDisplayText] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [displayTitle] = React.useState(props.displayTitle !== undefined ? props.displayTitle : true);
    const [displayBg] = React.useState(props.displayBg !== undefined ? props.displayBg : true);

    React.useEffect(() => {
        const openRate = 0.58;
        const responseRate = 0.25;
        try {
            console.log(`Currency ${currency}, Monthly Budget ${monthlyBudget}`);
            setProspects(budget_to_prospects[currency][monthlyBudget])
            setOpens(Math.round(prospect * openRate));
            setReponses(Math.round(prospect * responseRate));
            setConversionRateLeads(getPercentage(dealValue, salesCycle));
            setLeads(Math.round(prospect * conversionRateLeads));
            setProposals(Math.round(leads * conversionRateProposals));
            setDeals(Math.round(proposals * conversionRateDeals));
            setReturnI(Math.round(dealValue * deals));
            setDisplayText(true);
            setError(null);
        } catch (error){
            console.error(error.message);
            setError(error.message);
        }
        
    }, [monthlyBudget, salesCycle, dealValue, prospect, conversionRateLeads, leads, proposals, deals, currency])

    function formatPercentage(decimal) {
        const fixedValue = decimal < 0.1 ? 2 : 0;
        return (decimal * 100).toFixed(fixedValue) + '%';
    }

    const handleCurrencyChange = (e) => {
        setMonthlyBudget(steps[e.target.value][steps[currency].indexOf(monthlyBudget)]);
        setCurrency(e.target.value);
    }

    return (
        <div 
            className='orchidea-leads-forecast' 
            style={{
                background: displayBg === true ? 'linear-gradient(100deg, #e6e5e866 20%, rgba(229, 228, 232, 0) 70%)' : 'transparent',
                paddingTop: props.paddingTopIn !== undefined ? props.paddingTopIn : 'auto'
            }}
            ref={props.pricingRef}
        >
            {displayTitle && <div className='title'>
                <h2><span>Pricing</span> & Campaign Results for OrchideaLeads</h2>
            </div>}
            <div className='forecast-widget--container'>
                <div className='forecast-widget'>
                    <div className='forecast-widget--top'>
                        <div className='params'>
                            <div className='parameter' style={{paddingBottom: '20px'}}>
                                <div className='top-parameter'>
                                    <div className='number'>
                                        <p>1</p>
                                    </div>
                                    <p>Monthly budget</p>
                                </div>
                                <div className='input-parameter'>
                                    <div className='top-details'>
                                        <select className='currency-select' value={currency} onChange={handleCurrencyChange}>
                                            <option value="£">£</option>
                                            <option value="$">$</option>
                                            <option value="€">€</option>
                                        </select>
                                        <p className='monthly-budget-value'>{monthlyBudget.toLocaleString('en-US')}{monthlyBudget > 10999 ? '+' : ''}</p>
                                    </div>
                                    <MonthlyBudgetSlider setMonthlyBudget={setMonthlyBudget} steps={steps[currency]}/>
                                </div>
                            </div>

                            <div className='parameter'>
                                <div className='top-parameter'>
                                    <div className='number'>
                                        <p>2</p>
                                    </div>
                                    <p>What is your average deal value?</p>
                                </div>
                                <div className='input-parameter'>
                                    <div className='input-parameter-row'>
                                        <div className='input-field'>
                                            <select onChange={handleCurrencyChange} value={currency}>
                                                <option value="£">£</option>
                                                <option value="$">$</option>
                                                <option value="€">€</option>
                                            </select>
                                            <input type='text' placeholder='2500' value={dealValue} onChange={(e) => setDealValue(e.target.value)} />
                                        </div>
                                        <p>per <br /> customer</p>
                                    </div>
                                </div>
                            </div>

                            <div className='parameter'>
                                <div className='top-parameter' style={{marginBottom: '8px'}}>
                                    <div className='number'>
                                        <p>3</p>
                                    </div>
                                    <p>How long is your average sales cycle?</p>
                                </div>
                                <div className='input-parameter'>
                                    <div className='top-details'>
                                        <p className='sales-cycle-value'>{salesCycleData[salesCycle]}{salesCycleData[salesCycle] > 10 ? '+' : ''}</p>
                                    </div>
                                    <SalesCycleSlider setSalesCycle={setSalesCycle} />
                                    <p className='sales-cycle-label'>month sales cycle</p>
                                </div>
                            </div>
                        </div>
                        <img src={funnel_illustration} alt='funnel' className='funnel' />
                    </div>
                    <div className='forecast-widget--bottom'>
                        <div className='bottom-bar'>
                            <div className='bar-items'>
                                <div className='item' style={{left: '0px'}}>
                                    <img src={orchidea_logo_funnel} alt='orchidea-logo-funnel' />
                                    <p className='value'>{prospect.toLocaleString('en-US')}</p>
                                    <p className='rate' style={{display: 'none'}}>100%</p>
                                </div>
                                <div className='item' style={{left: '110px'}}>
                                    <img src={orchidea_logo_funnel} alt='orchidea-logo-funnel' />
                                    <p className='value'>{opens.toLocaleString('en-US')}</p>
                                    <p className='rate'>58%</p>
                                </div>
                                <div className='item' style={{left: '220px'}}>
                                    <img src={orchidea_logo_funnel} alt='orchidea-logo-funnel' />
                                    <p className='value'>{responses.toLocaleString('en-US')}</p>
                                    <p className='rate'>25%</p>
                                </div>
                                <div className='item' style={{left: '330px'}}>
                                    <img src={orchidea_logo_funnel} alt='orchidea-logo-funnel' />
                                    <p className='value'>{leads.toLocaleString('en-US')}</p>
                                    <p className='rate'>{formatPercentage(conversionRateLeads)}</p>
                                </div>
                                <div className='item' style={{left: '440px'}}>
                                    <img src={orchidea_logo_funnel} alt='orchidea-logo-funnel' style={{display: 'none'}} />
                                    <div style={{height: '64px', width: '64px'}}></div>
                                    <p className='value'>{proposals}</p>
                                    <p className='rate'>{formatPercentage(conversionRateProposals)}</p>
                                </div>
                                <div className='item' style={{left: '550px'}}>
                                    <img src={orchidea_logo_funnel} alt='orchidea-logo-funnel' style={{display: 'none'}} />
                                    <div style={{height: '64px', width: '64px'}}></div>
                                    <p className='value'>{deals}</p>
                                    <p className='rate'>{formatPercentage(conversionRateDeals)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {dealValue > 0 && dealValue < 1000 && <p className='warning-phrase'>
                *Although we work great for everyone, Orchidea gives the best ROI for clients that have a customer value of at least £1000.
            </p>}
            {displayText && !error && <p className='catch-phrase'>
                Would you invest 
                <span className='investment' style={{color: '#1dc79d'}}> {currency}{monthlyBudget.toLocaleString('en-US')} </span> 
                to generate 
                <span className='return' style={{color: '#7F63FF'}}> {currency}{returnI.toLocaleString('en-US')} </span> 
                each month?
            </p>}
            {error && <p className='error-phrase'>
                {error}
            </p>}
        </div>
    );
}