import React from 'react';
import './ContactForm.css';

export default function ContactForm(){
    const [formData, setFormData] = React.useState({
        fname: '',
        lname: '',
        email: '',
        company: '',
        phone: '',
        howHeardAboutUs: '',
        message: ''
    });
    const [displaySuccess, setDisplaySucess] = React.useState(false);
    const [displayError, setDisplayError] = React.useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisplaySucess(false);
        setDisplayError(false);
        const URL = `https://17uttfecb8.execute-api.eu-west-2.amazonaws.com/Prod/send-email`;
        const options = {
            method: 'POST',
            body: JSON.stringify(formData)
        }
        try{
            console.log(options.body);
            const response = await fetch(URL, options);
            await response.json();
            setDisplaySucess(true);
        } catch (e){
            console.log(e);
            setDisplayError(true);
        }
    }

    return (
        <form className='contact-form' onSubmit={handleSubmit}>
            <div className='title'>
                <h2>Send an enquiry</h2>
            </div>
            <div className='form-group'>
                <label>First Name*</label>
                <input type='text' required value={formData.fname} name='fname' onChange={handleChange} />
            </div>
            <div className='form-group'>
                <label>Last Name*</label>
                <input type='text' required value={formData.lname} name='lname' onChange={handleChange} />
            </div>
            <div className='form-group-double'>
                <div className='form-group' style={{marginRight: '25px'}}>
                    <label>Work email*</label>
                    <input type='email' required value={formData.email} name='email' onChange={handleChange} />
                </div>
                <div className='form-group'>
                    <label>Phone number</label>
                    <input type='text' value={formData.phone} name='phone' onChange={handleChange} />
                </div>
            </div>
            <div className='form-group'>
                <label>Company / Website*</label>
                <input type='text' required value={formData.company} name='company' onChange={handleChange} />
            </div>
            <div className='form-group'>
                <label>How did you hear about us?</label>
                <input type='text' value={formData.howHeardAboutUs} name='howHeardAboutUs' onChange={handleChange} />
            </div>
            <div className='form-group'>
                <label>Message:</label>
                <textarea type='text' value={formData.message} name='message' onChange={handleChange} />
            </div>
            <button type='submit'>
                Submit
            </button>
            <p>By clicking submit, you agree the <a href='google.com'>Privacy Policy</a></p>
            {displaySuccess && <div className='success-message message'>
                <p>Your enquiry has been sent successfully.</p>
            </div>}
            {displayError && <div className='error-message message'>
                <p>An error occured. Your message could not be sent.</p>
            </div>}
        </form>
    );
}