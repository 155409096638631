import React from 'react';
import './../LogInAdmin/LogInAdmin.css';
import {CognitoUserPool, CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js'
import api_config from '../../../api_config.json';
import {Link} from 'react-router-dom';

export default function SetPassword(props) {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('');

    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(null);


    const handleLogin = async (e) => {
        e.preventDefault();

        if (newPassword !== newPasswordConfirm) {
            setError('New Passwords do not match');
            return;
        }

        const userPool = new CognitoUserPool({
            UserPoolId: api_config.UserPoolId,
            ClientId: api_config.ClientId
        })
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        });
        
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });
        
        setError(null);
        setSuccess(false);

        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (result) => {
                setError('Password was already set. Please login.');
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                delete userAttributes.email_verified;
                delete userAttributes.email;
                cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
                    onSuccess: (result) => {
                        setSuccess(true);
                    },
                    onFailure: (err) => {
                        setError(err.message);
                    }
                });
                
            },
            onFailure: (err) => {
                setError(err.message);
            },
        });
    }


    return (
        <div className="login">
            <div className="login--form set-password--form">
                <h1 style={{textAlign: 'center'}}>Set Password</h1>
                <p>Orchidea Blog - Admin</p>
                <div className='login--form-inputs'>
                    <form onSubmit={handleLogin} className='from--login'>
                        <input type="text" required placeholder="Email Address" onChange={(e) => setUsername(e.target.value)} value={username}/>
                        <input type="password" required placeholder="Temporary Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                        <input type="password" required placeholder="New Password" onChange={(e) => setNewPassword(e.target.value)} value={newPassword} />
                        <input type="password" required placeholder="New Password Confirm" onChange={(e) => setNewPasswordConfirm(e.target.value)} value={newPasswordConfirm} />
                        <button type='submit'>Set Password</button>
                    </form>
                    {error && <div className='error'>
                        <p>{error}</p>
                    </div>}
                    {success && <div className='success'>
                        <p>Password was set successfully. Please <Link to='/admin'>login</Link>.</p>
                    </div>}
                </div>
            </div>
        </div>
    );
}