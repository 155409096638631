import React, { useState, useEffect } from 'react';

export default function MonthlyBudgetSlider(props) {
    const [sliderValue, setSliderValue] = useState(0);
    const [min] = useState(0);
    const [max, setMax] = useState(0);

    useEffect(() => {
        setMax(props.steps.length - 1);
        if (sliderValue < 0) {
            setSliderValue(0);
        } else if (sliderValue > max) {
            setSliderValue(props.steps.length - 1);
        }
        props.setMonthlyBudget(props.steps[sliderValue]);
    }, [sliderValue, props, max]);

    const handleChange = (e) => {
        setSliderValue(e.target.value);
    };

    const percentage = (sliderValue / max) * 100;

    return (
    <div className='input-range-container'>
        <input type='range' min={min} value={sliderValue} max={max} step='1' onChange={handleChange} />
        <div className='selector-input-range' style={{left: `${percentage}%`}}>
            <div className='SelectBtn-input-range'></div>
        </div>
        <div className="progress-bar-input-range" style={{width: `${percentage}%`}}></div>
    </div>
    );
};
