import React from 'react';
import './ContactMainSection.css';
import ContactForm from './ContactForm/ContactForm';
import CalendarContainer from './CalendarContainer/CalendarContainer';

export default function ContactMainSection(){
    return (
        <div className='contact-main-section'>
            <CalendarContainer />
            <ContactForm />
        </div>
    );
}