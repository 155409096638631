import React from 'react';
import './BlogPost.css';
import Navbar from '../../../Navbar/Navbar';
import Footer from '../../../Footer/Footer';
import { useParams } from 'react-router-dom';
import { format, parse } from 'date-fns';
import PostSuggestions from '../../../utils/PostSuggestions/PostSuggestions';

export default function BlogPost(){
    const [post, setPost] = React.useState(null);
    const { id } = useParams();

    const fetchPost = async () => {
        const URL = `https://17uttfecb8.execute-api.eu-west-2.amazonaws.com/Prod/posts/get/${id}`;
        console.log(URL);
        const options = {
            method: 'GET'
        }
        try{
            const response = await fetch(URL, options);
            const response_data = await response.json();
            console.log(response_data);
            setPost(response_data);
        } catch (e){
            console.log(e);
            window.location.href = '/not-found';
        }
    }

    React.useEffect(() => {
        fetchPost()
    }, [])

    function formatDate(dateStr) {
        const parsedDate = parse(dateStr, 'yyyy-MM-dd', new Date());
        const dayWithSuffix = format(parsedDate, 'do');
        const month = format(parsedDate, 'MMMM');
        const year = format(parsedDate, 'yyyy');
    
        return `the ${dayWithSuffix} of ${month} ${year}`;
    }

    function RichTextContent(props) {
        return <div dangerouslySetInnerHTML={{ __html: props.content }} />;
    }

    return (
        <div className='blog-post'>
            <Navbar buttonColor='#d8f7ee' buttonColorHover='#d9f3ec' colorText='#25262B' />
            {post && <div className='blog-post-page'>
                <div className='header-banner'>
                    {post.image.endsWith('.svg') ? 
                        (<object type='image/svg+xml' data={post.image}>Your browser does not support svg</object>) :
                        (<img src={post.image} alt='post-illustration' />)
                    }
                </div>
                <div className='metadata'>
                    <p>By {post.author}, on {formatDate(post.custom_date)}</p>
                </div>
                <div className='post-core'>
                    <h1>{post.title}</h1>
                    <hr />
                    {<RichTextContent content={post.content} />}
                </div>
            </div>}
            <PostSuggestions title='Discover more on Our Blog' type='blog' />
            <Footer />
        </div>
    )
}