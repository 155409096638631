import React from 'react';
import './AboutBannerImg.css';
import banner from './assets/banner-img.svg';
import star from './assets/star.svg';

export default function AboutBannerImg(){
    return (
        <div className='about-banner'>
            <div className='img-container'>
                <img src={banner} alt='banner' className='banner-img'/>
                <img src={star} alt='green-star' className='star'/>
            </div>
            <p>We're on a mission to change your view of <span>sales, recruitment</span> and <span>marketing</span>.</p>
        </div>
    );
}