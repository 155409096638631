import React from 'react';
import './ResourcesHomePage.css';
import item_footer from './assets/item-footer.svg';
import arrow_show_more from './assets/arrow-show-more.svg';

export default function ResourcesHomePage(){
    const [type, setType] = React.useState('blog');
    const [posts, setPosts] = React.useState([]);

    const fetchPosts = async () => {
        const URL = `https://17uttfecb8.execute-api.eu-west-2.amazonaws.com/Prod/posts/get-all/3?page=1&publish_status=published&post_type=${type}`;
        const options = {
            method: 'GET'
        }
        try{
            const response = await fetch(URL, options);
            const response_data = await response.json();
            setPosts([...response_data.data]);
        } catch (e){
            console.log(e);
        }
    };

    React.useEffect(() => {
        fetchPosts();
    }, [type])

    const handleClickPost = (post) => {
        if(post.post_type === 'blog'){
            window.location.href = `/blog/post/${post.id}`;
        } else if (post.post_type === 'case-study'){
            window.location.href = `/case-studies/post/${post.id}`;
        }
    }

    return (
        <div className="resources-section">
            <h1>The latest free resourceses for customer <br /> <span>centric marketing</span></h1>
            <div className="resources">
                <div 
                    onClick={() => setType('blog')}
                    className="resource" 
                    style={{borderBottom: type === 'blog' ? 'solid 1px #25262B' : 'none'}}
                >
                    <p>Blog posts</p>
                </div>
                <div 
                    className="resource"
                    onClick={() => setType('case-study')}
                    style={{borderBottom: type === 'case-study' ? 'solid 1px #25262B' : 'none'}}
                >
                    <p>Case studies</p>
                </div>
                {/* <div className="resource" id='guides-label'>
                    <p>Guides</p>
                </div>
                <div className="resource" id='videos-label'>
                    <p>Videos</p>
                </div>
                
                <div className="resource" id='events-label'>
                    <p>Events</p>
                </div> */}
            </div>
            <div className="items">
                {posts.map(post => 
                    <div className="item" key={post.id} onClick={() => handleClickPost(post)}>
                        <div 
                            className="img" 
                            style={{background: type === 'blog' ? `url(${post.image}) center center / cover no-repeat` : '#FFFFFF'}}
                        >
                            {type === 'case-study' && <img src={post.image} />}
                        </div>
                        <div className="content">
                            <h3 className="title">{post.title}</h3>
                            <div className="item-footer">
                                <img src={item_footer} alt="" />
                            </div>
                        </div>
                    </div>
                )}
                <div id='show-more'>
                    <div className='show-more--button'>
                        <p>Show more</p>
                        <img src={arrow_show_more} alt='show-more'/>
                    </div>
                </div>
            </div>
        </div>
    );
}