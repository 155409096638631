import React from 'react';
import './AllPosts.css';
import useToken from '../../Hooks/useToken';

export default function AllPosts(props){
    const page_size = 10;
    const [posts, setPosts] = React.useState([]);
    const {token} = useToken();
    const [filters, setFilters] = React.useState({
        'page': 1,
        'post_type': '',
        'search': '',
        'publish_status': ''
    });

    React.useEffect(() => {
        setFilters(prevFilters => ({
            ...prevFilters,
            'page': 1
        }));
    }, [filters.post_type, filters.search, filters.publish_status]);

    const fetchPosts = async () => {
        const filterStr = Object.keys(filters).filter(key => filters[key] !== '').map(key => `${key}=${filters[key]}`).join('&');
        const URL = `https://17uttfecb8.execute-api.eu-west-2.amazonaws.com/Prod/posts/get-all/${page_size}?${filterStr}`;
        console.log(filterStr);
        const options = {
            headers: {
                Authorization: token
            },
            method: 'GET'
        }
        try{
            const response = await fetch(URL, options);
            const response_data = await response.json();
            setPosts(response_data.data);
            console.log(response_data);
        } catch (e){
            console.log(e);
        }
    };

    React.useEffect(() => {
        console.log('run?')
        fetchPosts();
    }, [filters])

    

    return (
        <div className='all-posts'>
            <div className='filters'>
                <select value={filters.post_type} onChange={(e) => setFilters(prev => ({...prev, post_type: e.target.value}))}>
                    <option value=''>All posts</option>
                    <option value='blog'>Blog posts</option>
                    <option value='case-study'>Case studies</option>
                </select>
                <select value={filters.publish_status} onChange={(e) => setFilters(prev => ({...prev, publish_status: e.target.value}))}>
                    <option value=''>All status</option>
                    <option value='published'>Published</option>
                    <option value='draft'>Draft</option>
                </select>
                <input type='text' placeholder='Search URL...' onChange={(e) => setFilters(prev => ({...prev, search: e.target.value}))}/>
            </div>
            <div className='posts-table'>
                <table>
                    <thead>
                        <tr>
                            <th>URL</th>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Status</th>    
                        </tr>
                    </thead>
                    <tbody>
                        {posts.map(post => <tr onClick={() => props.editPost(post.id)} key={post.id}>
                            <td>{post.id}</td>
                            <td>{post.title}</td>
                            <td>{post.post_type ? post.post_type : ''}</td>
                            <td>{post.custom_date ? post.custom_date : ''}</td>
                            <td>{post.publish_status ? post.publish_status : ''}</td>
                        </tr>)}
                        {posts.length === 0 && <tr>
                            <td colSpan={5}>No post matches this request.</td>
                        </tr>}
                    </tbody>
                </table>
            </div>
            <div className='bottom-btns'>
                {filters.page > 1 && <button onClick={() => setFilters(prev => ({...prev, page: prev.page-1}))}>Previous page</button>}
                {posts.length === page_size && <button onClick={() => setFilters(prev => ({...prev, page: prev.page+1}))}>Next page</button>}
            </div>
        </div>
    );
}