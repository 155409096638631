import React from 'react';
import './AboutFriendlyExpertsSection.css';
import star from './assets/star.svg';

export default function AboutFriendlyExpertsSection(props){
    return (
        <div className='friendly-experts'>
            <p>A Super Friendly Bunch of Well-Renowned <br /> Marketing, Sales & Tech Gurus!</p>
            <div className='buttons'>
                <button onClick={props.goToRef} disabled={true} style={{cursor: 'not-allowed'}}>Meet the team</button>
                <button className='learn-more' onClick={() => window.location.href = '/contact'}>
                    Hire us
                    <img src={star} alt='green-star' />
                </button>
            </div>
        </div>
    );
}