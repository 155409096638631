import React from 'react';
import './LetsBloomHirePage.css';
import lets_bloom_shape_1 from './assets/lets_bloom1.png';
import lets_bloom_shape_2 from './assets/lets_bloom2.png';
import lets_bloom_illustration from './assets/lets_bloom_illustration.svg';

export default function LetsBloomHirePage(){
    return (
        <div className="lets-blooom-section">
            <img src={lets_bloom_shape_1} alt="" className="letsblooms-blue" />
            <img src={lets_bloom_shape_2} alt="" className="letsblooms-gray" />
            <div className="inside-lets-blooom-section">
                <img src={lets_bloom_illustration} alt="lets-bloom-illustration" />
                <h1>LET'S BLOOM</h1>
                <p>We are your team, <br /> not just a tool!</p>
                <div className="lets-blooom-section--buttons">
                    <button onClick={() => window.location.href = '/contact'}>Book a Call</button>
                    <button className="learn-more" onClick={() => window.location.href = '/pricing'}>See our Pricing</button>
                </div>
            </div>
        </div> 
    );
}