import React from 'react';
import './OrchideaHireWithWithout.css';
import job_boards from './assets/without/job_boards.svg';
import adequate_contacts from './assets/without/adequate-contacts.svg';
import lack_time from './assets/without/lack_time.svg';
import wrong_hires from './assets/without/wrong_hires.svg';
import without_flower_left from './assets/without/flower_left.svg';
import without_flower_right from './assets/without/flower_right.svg';
import with_flower_left from './assets/with/flower_left.svg';
import with_flower_right from './assets/with/flower_right.svg';
import lets_bloom from './assets/with/lets-bloom.png';
import headhunting from './assets/with/headhunting.svg';
import global_network from './assets/with/global_network.svg';
import dedicated_experts from './assets/with/dedicated_experts.svg';
import perfect_fit from './assets/with/perfect_fit.svg';

export default function OrchideaHireWithWithout(){
    const [withOrchidea, setWithOrchidea] = React.useState(false);

    return (
         <div className='orchidea-hire-with-without'>
            {!withOrchidea && <div className='without'>
                <div className='top-side'>
                    <div className='toggle'>
                        <div className='inside-circle' onClick={() => setWithOrchidea(prev => !prev)}></div>
                        <p className='inside-text'>Without <br /> Orchidea<span>Hire</span></p> 
                    </div>
                    <img src={lets_bloom} alt='lets-bloom' className='fade-in'/>
                </div>
                <div className='items'>
                    <div className='item'>
                        <p className='title'>OVER RELIANCE ON <br /> JOB BOARDS</p>
                        <img src={job_boards} alt='over-reliance-on-job-boards'/>
                        <div className='highlight'>
                            <p>Restricted Talent Pool</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>LACK OF <br /> ADEQUATE CONTACTS</p>
                        <img src={adequate_contacts} alt='lack-of-adequate-contacts'/>
                        <div className='highlight'>
                            <p>Poor Network & DB</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>LACK OF <br /> TIME / EXPERTISE</p>
                        <img src={lack_time} alt='lack-of-time'/>
                        <div className='highlight'>
                            <p>Ineffective Campaigns</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>WRONG HIRES & <br /> LOW TENURE</p>
                        <img src={wrong_hires} alt='wrong-hires'/>
                        <div className='highlight'>
                            <p>Lost Revenue</p>
                        </div>
                    </div>
                </div>  
                <img src={without_flower_left} alt='without-flower-left' className='left-flower fade-in'/>  
                <img src={without_flower_right} alt='without-flower-right' className='right-flower fade-in'/>  
            </div>}
            {withOrchidea && <div className='with'>
                <div className='top-side'>
                    <div className='toggle'>
                        <p className='inside-text'>With <br /> Orchidea<span>Hire</span></p> 
                        <div className='inside-circle' onClick={() => setWithOrchidea(prev => !prev)}></div>
                    </div>                    
                </div>
                <div className='items'>
                    <div className='item'>
                        <p className='title'>PRO-ACTIVE <br /> HEADHUNTING</p>
                        <img src={headhunting} alt='headhunting'/>
                        <div className='highlight'>
                            <p>Text Too Long</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>GLOBAL NETWORK & <br /> DATABASES</p>
                        <img src={global_network} alt='global_network'/>
                        <div className='highlight'>
                            <p>Golden Segmentation</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>DEDICATED EXPERTS <br /> HANDLE IT ALL</p>
                        <img src={dedicated_experts} alt='dedicated_experts'/>
                        <div className='highlight'>
                            <p>Time Availibity & Results</p>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>WE GET YOU THE <br /> PERFECT FITS</p>
                        <img src={perfect_fit} alt='perfect_fit'/>
                        <div className='highlight'>
                            <p>Gained Revenue</p>
                        </div>
                    </div>
                </div>  
                <div className='text'>
                    <p>
                        <span>Wider reach, thorough research, expanded recruitment efforts, 
                        and efficient screening</span> to improve the effectiveness of the hiring process.
                    </p>
                    <p style={{marginTop: '30px'}}>
                        <span>With us, your human resources teams can focus on other critical 
                        aspects of the hiring process</span>, such as interviewing and onboarding, while we 
                        handle the time-consuming and complex task of prospecting candidates.
                    </p>
                </div>
                <img src={with_flower_left} alt='without-flower-left' className='left-flower fade-in'/>  
                <img src={with_flower_right} alt='without-flower-right' className='right-flower fade-in'/>  
            </div>}
        </div>
    )
}