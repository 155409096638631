import React from 'react';
import './CaseStudiesPage.css';
import Navbar from '../../Navbar/Navbar';
import ResourceBanner from '../../utils/ResourceBanner/ResourceBanner';
import bannerImg from './assets/banner-img.svg';
import CaseStudiesIntro from './CaseStudiesIntro/CaseStudiesIntro';
import Footer from '../../Footer/Footer';
import CaseStudiesResults from './CaseStudiesResults/CaseStudiesResults';
// import CaseStudiesFeatured from './CaseStudiesFeatured/CaseStudiesFeatured';
import PostsSection from '../../utils/PostsSection/PostsSection';

export default function CaseStudiesPage(){
    return (
        <div className='case-studies-page'>
            <Navbar buttonColor='#d8f7ee' buttonColorHover='#d9f3ec' colorText='#25262B' />
            <ResourceBanner image={bannerImg} title='Case Studies' subtitle='Your Success is Our Succes' />
            <CaseStudiesIntro />
            <CaseStudiesResults />
            {/* <CaseStudiesFeatured /> */}
            <PostsSection pageTitle='All success stories' type='case-study'/>
            <Footer />
        </div>
    );
}