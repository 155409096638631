import React from 'react';
import './PricingWidget.css';
import OrchideaLeadsPrice from '../../../utils/OrchideaLeadsForecast/OrchideaLeadsForecast';
import OrchideaSpendPrice from '../../OrchideaSpendPage/OrchideaSpendPricing/OrchideaSpendPricing';

export default function PricingWidget(){
    const [service, setService] = React.useState('leads');

    return (
        <div className='pricing-widget'>
            <div className='top-bar'>
                <div 
                    className={service === 'hire' ? 'bar-item active-hire' : 'bar-item'} 
                    onClick={() => setService('hire')}
                >
                    <p>Orchidea<span>Hire</span></p>
                </div>
                <div 
                    className={service === 'leads' ? 'bar-item active-leads' : 'bar-item'}  
                    onClick={() => setService('leads')}
                >
                    <p>Orchidea<span>Leads</span></p>
                </div>  
                <div 
                    className={service === 'spend' ? 'bar-item active-spend' : 'bar-item'} 
                    onClick={() => setService('spend')}
                >
                    <p>Orchidea<span>Spend</span></p>
                </div>
            </div>
            <div className='price-container'>
                {service === 'leads' && <OrchideaLeadsPrice displayTitle={false} displayBg={false} paddingTopIn='20px'/>}
                {service === 'spend' && <OrchideaSpendPrice displayTitle={false} paddingTopIn='10px' />}
                {service === 'hire' && <div className='hire-price'>
                    <h2>Let's <span>talk</span>!</h2>
                    <p>OrchideaHire's pricing starts from <span>£1,500</span></p>    
                    <button onClick={() => window.location.href = '/contact'}>Book a call</button>
                </div>}
            </div>
        </div>
    );
}