import React from 'react';
import './OrchideaHirePage.css';
import Navbar from '../../Navbar/Navbar.js';
import IntroOrchideaHirePage from './IntroOrchideaHirePage/IntroOrchideaHirePage';
import BannerOrchideaHirePage from './BannerOrchideaHirePage/BannerOrchideaHirePage';
import OrchideaHireWidget from './OrchideaHireWidget/OrchideaHireWidget';
import OrchideaHireProcess from './OrchideaHireProcess/OrchideaHireProcess';
import OrchideaHireForecast from './OrchideaHireForecast/OrchideaHireForecast';
import OrganicGrowthIsProgressiveHire from './OrganicGrowthIsProgressiveHire/OrganicGrowthIsProgressiveHire';
import CaseStudiesOrchideaHire from './CaseStudiesOrchideaHire/CaseStudiesOrchideaHire';
import OrchideaHireCounter from './OrchideaHireCounter/OrchideaHireCounter';
import LetsBloomHirePage from './LetsBloomHirePage/LetsBloomHirePage';
import Footer from '../../Footer/Footer';
import OrchideaHireWithWithout from './OrchideaHireWithWithout/OrchideaHireWithWithout';

export default function OrchideaHirePage(){
    return (
        <div className='orchidea-hire-page'>
            <Navbar buttonColor='#E5FBF5' buttonColorHover='#BAF6E6' colorText='#23E9C0' />
            <IntroOrchideaHirePage />
            <OrchideaHireWithWithout />
            <BannerOrchideaHirePage />
            <OrchideaHireWidget />
            <OrchideaHireProcess />
            <OrchideaHireForecast />
            <OrganicGrowthIsProgressiveHire />
            <CaseStudiesOrchideaHire />
            <OrchideaHireCounter />
            <LetsBloomHirePage />
            <Footer />
        </div>
    )
}