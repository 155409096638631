import React from 'react';
import './CaseStudiesResults.css';

export default function CaseStudiesResults(){
    return (
        <div className='case-studies-results'>
            <div className='top-banner'>
                <h2>Our Results</h2>
            </div>
            <div className='figures'>
                <div className='figure-row'>
                    <div className='figure'>
                        <p className='number'>68m</p>
                        <p className='label'>prospects & candidates <br /> engaged</p>
                        <div className='highlight'></div>
                    </div>
                    <div className='figure'>
                        <p className='number'>22,000</p>
                        <p className='label'>campaigns delivered</p>
                        <div className='highlight'></div>
                    </div>
                    <div className='figure'>
                        <p className='number'>360</p>
                        <p className='label'>clients</p>
                        <div className='highlight'></div>
                    </div>
                </div>
                <div className='figure-row'>
                    <div className='figure'>
                        <p className='number'>£12</p>
                        <p className='label'>cost per lead</p>
                        <div className='highlight'></div>
                    </div>
                    <div className='figure'>
                        <p className='number'>12</p>
                        <p className='label'>lead rate</p>
                        <div className='highlight'></div>
                    </div>
                    <div className='figure'>
                        <p className='number'>2,000</p>
                        <p className='label'>leads & candidates</p>
                        <div className='highlight'></div>
                    </div>
                </div>
            </div>
        </div>
    );
}