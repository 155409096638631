import React from 'react';
import './OrchideaStats.css';
import logo from './assets/orchidea-logo.svg';
import star from './assets/star.svg';

export default function OrchideaStats() {
    return (
        <div className='orchidea-stats'>
            <div className='intro-container'>
                <img src={logo} alt='orchidea-logo' />
                <h1>Orchidea <span>Stats</span></h1>
            </div>
            <div className='columns-container'>
                <div className='columns'>
                    <div className='column'>
                        <img src={star} alt='green-star' className='star' />
                        <p className='top-text'>We engage over</p>
                        <p className='figure'>3,300</p>
                        <p className='bottom-text'>new propsect <br />per hour</p>
                    </div>
                    <div className='column'>
                        <img src={star} alt='green-star' className='star' />
                        <p className='top-text'>Each year we generate</p>
                        <p className='figure'>135</p>
                        <p className='bottom-text'>of deals for <br />our clients</p>
                    </div>
                    <div className='column'>
                        <img src={star} alt='green-star' className='star' />
                        <p className='top-text'>We have experience of</p>
                        <p className='figure'>22,000</p>
                        <p className='bottom-text'>previous <br /> campaigns</p>
                    </div>
                    <div className='column'>
                        <img src={star} alt='green-star' className='star' />
                        <p className='top-text'>We support over</p>
                        <p className='figure'>360</p>
                        <p className='bottom-text'>sales / marketing / <br /> recruitment teams</p>
                    </div>
                </div>
            </div>
            <div className='green-banner'></div>
        </div>
    );
}