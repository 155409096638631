import React, { useEffect, useState } from 'react';
import { S3 } from 'aws-sdk';
import './Media.css';
import MediaItem from './MediaItem/MediaItem';

export default function Media(props) {
    const [newFile, setNewFile] = useState(null);
    const [mediaList, setMediaList] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const page_size = 9;

    // Initialize the S3 instance
    const s3 = new S3({
        accessKeyId: 'AKIARBXI6C42FWXEVYHP',
        secretAccessKey: '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',
        region: 'eu-west-2'
    });

    const getFileType = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(extension)) {
            return 'image';
        }
        if (['mp4', 'webm', 'ogg', 'avi'].includes(extension)) {
            return 'video';
        }
        return 'other';
    };

    const uploadToS3 = async () => {
        if (!newFile) return;
    
        const contentType = newFile.type; // Use the file's type property
    
        const params = {
            Bucket: 'orchidea-media-bucket',
            Key: `${Date.now()}_${newFile.name}`,
            Body: newFile,
            ContentType: contentType // Set the Content-Type in the upload params
        };
    
        try {
            const result = await s3.upload(params).promise();
            setMediaList([result.Location, ...mediaList]);
            setNewFile(null);
        } catch (error) {
            console.error("Error uploading to S3", error);
        }
    };
    

    const fetchMedia = async () => {
        try {
            const params = {
                Bucket: 'orchidea-media-bucket',
                MaxKeys: page_size,
                StartAfter: mediaList.length ? mediaList[mediaList.length - 1] : undefined
            };
    
            const data = await s3.listObjectsV2(params).promise();
    
            const sortedMedia = data.Contents;
            setMediaList(prevMediaList => [...prevMediaList, ...sortedMedia.map(item => item.Key)]);
    
            if (sortedMedia.length < page_size) {
                setHasMore(false);
            }
    
        } catch (error) {
            console.error("Error fetching media list", error);
        }
    };
    

    useEffect(() => {
        fetchMedia();
    }, [page]);

    return (
        <div className='media'>
            <div className='input-form'>
                <label>Upload a file</label>
                <input 
                    type='file' 
                    onChange={(e) => setNewFile(e.target.files[0])}
                />
                <button onClick={uploadToS3}>Upload</button>
            </div>
            <div className='media-all'>
                {mediaList.map(media => {
                    const fileType = getFileType(media);
                    const mediaURL = `https://orchidea-media-bucket.s3.eu-west-2.amazonaws.com/${media}`;
                    return (<MediaItem fileType={fileType} mediaURL={mediaURL} key={media} />);
                })}
            </div>
            {hasMore &&
                <button onClick={() => setPage(prevPage => prevPage + 1)}>Load More</button>
            }
        </div>
    );
}
