import React from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import './NotFound.css';

export default function NotFound() {
  return (
    <div className="not-found">
      <Navbar buttonColor='#d8f7ee' buttonColorHover='#d9f3ec' colorText='#25262B' />
      <div className='not-found-content'>
        <h1>Page Not Found</h1>
        <h2>404 Error</h2>
        <p>Sorry. The page you are looking for could not be found.</p>
      </div>
      <Footer />
    </div>
  );
};
