import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = (props) => {
    const handleEditorChange = (content, editor) => {
        props.setEditorContent(content);
    };

  return (
    <Editor
      initialValue={props.initialValue}
      apiKey='3psivi4f1z8zqypicc8tcbk579efeemae8daaf76f5c9rj8e'
      init={{
        height: 500,
        width: 800,
        menubar: false,
        plugins: 'lists image link table wordcount preview fullscreen visualblocks charmap fullscreen media',
        toolbar1: 'undo redo | formatselect | bold italic underline | link image media | wordcount preview fullscreen',
        toolbar2: 'alignleft aligncenter alignright | h2 h3 | bullist numlist outdent indent'
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default TextEditor;
