import React from 'react';
import './LogInAdmin.css';
import api_config from '../../../api_config.json';
import {CognitoUserPool, CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';
import jwtDecode from 'jwt-decode';

export default function LogInAdmin(props){
    const [error, setError] = React.useState(null);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        const userPool = new CognitoUserPool({
            UserPoolId: api_config.UserPoolId,
            ClientId: api_config.ClientId
        })
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        });
        
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });
        
        setError(null);

        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (result) => {
                const userInfo  = jwtDecode(result.idToken.jwtToken);
                console.log(userInfo);
                props.setUser({
                    email: userInfo.email, 
                    groups: userInfo['cognito:groups'] ? userInfo['cognito:groups'] : [],
                    fname: userInfo.given_name,
                    lname: userInfo.family_name,
                    picture: userInfo.picture
                });
                props.setToken(result.idToken.jwtToken);
            },
            onFailure: (err) => {
                setError(err.message);
            },
        });
    }

    return (
        <div className="login">
            <div className='login--inside'>
                <div className="login--form">
                    <h1>Log in</h1>
                    <p>Orchidea's Staff - Admin Portal</p>
                    <div className='login--form-inputs'>
                        <form onSubmit={handleLogin} className='from--login'>
                            <input type="text" required placeholder="Email Address" onChange={(e) => setUsername(e.target.value)} value={username}/>
                            <input type="password" required placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password}/>
                            <button type='submit'>Login</button>
                        </form>
                        {error && <div className='error'>
                            <p>{error}</p>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}