import React from 'react';
import './OrchideaGrowthIsProgressive.css';
import orchidea_growth_illustration from './assets/orchidea-growth.svg';

export default function OrchideaGrowthIsProgressive(){
    return (
        <div className="orchidea-leads-growth">
            <img src={orchidea_growth_illustration} alt="orchidea-leads" />
            <div className="orchidea-leads-growth-content">
                <h1>Organic Growth <br /> is <span>Progressive</span></h1>
                <img src={orchidea_growth_illustration} alt="orchidea-leads" className='illustration-responsive' />
                <p><span>Success comes from successful planning, expertise but most importantly consistency.</span></p>
                <p>It takes time to warm up leads process & fill up pipeline with consistent numbers, but Luckily Orchidea team knows just how to grow exponentially.</p>
                <p>Multiply your leads and fill up your sales pipeline.</p>
                <p>Let us do all the hard work, report the progress, optimise campaigns & hand you the perfect convertible hot leads on a silver platter.</p>
                <div className="orchidea-leads-growth-buttons">
                    <button onClick={() => window.location.href = '/spend'}>Orchidea Spend</button>
                </div>
                <p><span>Paid Ads to support the multichannel prospecting campaign and exponentially increase results and speed up the process up to 20x.</span></p>
            </div>
        </div>
    );
}