import React from 'react';
import './IntroOrchideaLeadsPage.css';
import orchidea_leads_intro_illustration from './assets/orchidea-leads-intro.svg';

export default function IntroOrchideaLeadsPage(props){
    return (
        <div className="orchidea-leads-intro">
            <img src={orchidea_leads_intro_illustration} alt="orchidea-leads" />
            <div className="orchidea-leads-intro-content">
                <h1>Orchidea<span>Leads</span></h1>
                <p className='subtitle'>for sales & marketing teams</p>
                <img src={orchidea_leads_intro_illustration} alt="orchidea-leads" className='illustration-responsive' />
                <p><span>Growing your business & acquiring new customers can be hard and time consuming, but it doesn't have to be!</span></p>
                <p>With Orchidea Leads, we take care of the whole prospecting process for you, increasing your pipeline with high-quality leads and allowing for more sales.</p>
                <p>Our team of experienced professionals utilizes a multichannel approach to generate leads from various sources, providing you with a steady flow of opportunities to close deals and grow your business.</p>
                <p>With tailored solutions and a track record of delivering outstanding results, partner with Orchidea Leads to increase your pipeline and achieve success.</p>
                <p><span>Boost your efforts or let us take over! Grow your company with Orchidea Leads.</span></p>
                <div className="orchidea-leads-intro-buttons">
                    <button onClick={() => window.location.href = '/contact'}>Let's Bloom</button>
                    <button className="learn-more" onClick={props.goToPricingRef}>See pricing</button>
                </div>
            </div>
        </div>
    );
}