import React from 'react';
import './OrchideaLeadsWidget.css';
import cold_call_icon from './assets/cold-call.svg';
import email_icon from './assets/email.svg';
import funnel_icon from './assets/funnel.svg';
import multi_channel_icon from './assets/multi-channel.svg';
import cold_call_illustration from './assets/illustrations/cold-call.svg';
import email_illustration from './assets/illustrations/email.svg';
import linkedin_illustration from './assets/illustrations/linkedin.svg';
import multi_channel_illustration from './assets/illustrations/multichannel.svg'
import star_icon from './assets/star.svg';
import arrow_right from './assets/arrow-right-circle.svg';
import arrow_left from './assets/arrow-left-circle.svg';

export default function OrchideaLeadsWidget(){
    const [active, setActive] = React.useState('multichannel');

    const collection = ['multichannel', 'linkedin', 'email', 'cold-call'];

    const illustrations = {
        'multichannel': multi_channel_illustration,
        'linkedin': linkedin_illustration,
        'email': email_illustration,
        'cold-call': cold_call_illustration
    }

    const textContent = {
        'multichannel': {
            title: 'Multichannel Full Service',
            content: <div>
                    <p>We use a multichannel approach for prospecting, reaching potential customers through <span>various communication channels</span>.</p>
                    <p> This strategy helps <span>increase engagement</span> rates and establish a <span>stronger brand</span> presence. </p> 
                    <p>As a full-service lead generation agency, we are able to tailor the strategy to your industry and area of activity, delivering maximum value.</p>
                </div>
        },
        'linkedin': {
            title: 'LinkedIn Leads',
            content: <div>
                <p>Our LinkedIn lead generation service connects businesses with potential customers using one of the most effective B2B social media channels available.</p>
                <p>We engage with hundreds of prospects daily, expanding your personal network and professional visibility, while contacting individuals with personalized messages. With over 900 million professionals on the platform, LinkedIn is a goldmine for finding decision-makers in your industry.</p>
            </div>
        },
        'email': {
            title: 'E-mail Campaigns',
            content: <div>
                <p>Our email prospecting service connects businesses with a high volume of potential customers through A/B campaigns of mass and targeted email campaigns.</p>
                <p>We source the right contacts and craft personalised messaging for maximum impact. Email is an effective channel for projecting your brand image with personalized design and messaging.</p>
            </div>
        },
        'cold-call': {
            title: 'Cold Calling',
            content: <div>
                <p>We use cold calling to generate leads and build relationships with potential customers. Our experienced team identifies contacts and crafts persuasive scripts that engage prospects and lead to long-term partnerships and increased revenue for your business.</p>
            </div>
        }
    }

    function handleClickImage() {
        var image = document.getElementById('widget-illustration-main');
        console.log(image.src);
        image.classList.remove("img-zoom-in");
        void image.offsetWidth; // force reflow
        image.classList.add("img-zoom-in");
    }

    function handleClickNext(){
        const index = collection.indexOf(active);
        const newIndex = (index + 1 === collection.length) ? 0 : index + 1;
        setActive(collection[newIndex]);
    }
    
    function handleClickPrevious(){
        const index = collection.indexOf(active);
        const newIndex = (index - 1 < 0 ) ? (collection.length - 1) : index - 1;
        setActive(collection[newIndex]);
    }
    
    return (
        <div className='orchidea-leads-widget'>
            <h1>Orchidea<span>Leads</span></h1>
            <div className='orchidea-leads-widget--widget'>
                <div className='widget--nav'>
                    <div className='box'>
                        <img src={star_icon} alt='star' className='star-icon'/>
                        <div className='large-item item' onClick={() => {setActive('multichannel'); handleClickImage()}}>
                            <div className='logo-container' style={{backgroundColor: active === 'multichannel' ? '#C7C0FC' : '#C6C7CC'}} >
                                <img src={multi_channel_icon} alt='multi-channel-icon'/>
                            </div>
                            <div className='text-container' style={{justifyContent: 'flex-start'}}>
                                <p style={{marginLeft: '40px'}}>Multichannel Full Service</p>
                            </div>
                        </div>
                    </div>
                    <div className='box' style={{borderTop: 'none', borderBottom: 'none'}}>
                        <img src={star_icon} alt='star' className='star-icon'/>
                        <div className='item' style={{top: '-35px'}} onClick={() => {setActive('linkedin'); handleClickImage()}}>
                            <div className='logo-container' style={{backgroundColor: active === 'linkedin' ? '#C7C0FC' : '#C6C7CC'}}>
                                <img src={funnel_icon} alt='linkedin-leads-icon'/>
                            </div>
                            <div className='text-container'>
                                <p>LinkedIn Leads</p>
                            </div>
                        </div>
                    </div>
                    <div className='box' style={{borderBottomRightRadius: '20px'}}>
                        <img src={star_icon} alt='star' className='star-icon'/>
                        <div className='item' style={{top: '-35px'}} onClick={() => {setActive('email'); handleClickImage()}}>
                            <div className='logo-container' style={{backgroundColor: active === 'email' ? '#C7C0FC' : '#C6C7CC'}}>
                                <img src={email_icon} alt='email-icon'/>
                            </div>
                            <div className='text-container'>
                                <p>E-mail Campaigns</p>
                            </div>
                        </div>
                        <div className='item' style={{bottom: '-35px'}} onClick={() => {setActive('cold-call'); handleClickImage()}}>
                            <div className='logo-container' style={{backgroundColor: active === 'cold-call' ? '#C7C0FC' : '#C6C7CC'}}>
                                <img src={cold_call_icon} alt='cold-call-icon'/>
                            </div>
                            <div className='text-container'>
                                <p>Cold Calling</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='widget-content'>
                    <img alt='arrow-left' src={arrow_left} id='widget-arrow-left' onClick={handleClickPrevious}/>
                    <div className='widget-illustration'>
                        <img src={illustrations[active]} alt='illustration-widget' id='widget-illustration-main' />
                    </div>
                    <div className='widget-text-content'>
                        <h2>{textContent[active].title}</h2>
                        {textContent[active].content}
                    </div>
                    <img alt='arrow-right' src={arrow_right} id='widget-arrow-right' onClick={handleClickNext} />
                </div>
            </div>
        </div>
    );
}