import React from 'react';
import './NavbarLogo.css';
import logo from './assets/logo.png';

export default function NavbarLogo(){
    return (
        <div className='navbar--logo' onClick={() => window.location.href='/'} >
            <img src={logo} alt='logo-navabar' />
            <h1>Orchidea</h1>
        </div>
    )
}