import React from 'react';
import './BlogAdmin.css';
import TopBar from './TopBar/TopBar';
import CreatePost from './CreatePost/CreatePost';
import AllPosts from './AllPosts/AllPosts';
import LogInAdmin from './LogInAdmin/LogInAdmin';
import useToken from '../Hooks/useToken';
import useUser from '../Hooks/useUser';
import Media from './Media/Media';
import EditPost from './EditPost/EditPost';

export default function BlogAdmin(props){
    const {token, setToken} = useToken(null);
    const {user, setUser} = useUser(null);
    const logout = () => {
        sessionStorage.removeItem('token');
        window.location.reload();
    }

    const [mode, setMode] = React.useState('list'); //in [list, create, media, edit]

    const [postEdit, setPostEdit] = React.useState(null);

    const handleEditPost = (postId) => {
        setPostEdit(postId);
        setMode('edit');
    }

    if(!token){
        return (
            <LogInAdmin setToken={setToken} setUser={setUser}/>
        );
    }


    return (
        <div className='blog-admin'>
            <TopBar user={user} setMode={setMode} logout={logout}/>
            {mode === 'create' && <CreatePost />}
            {mode === 'list' && <AllPosts editPost={handleEditPost} />}
            {mode === 'media' && <Media />}
            {mode === 'edit' && <EditPost id={postEdit} />}
        </div>
    );
}

