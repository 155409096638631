import React from 'react';
import './OrchideaLeadsPage.css';
import Navbar from '../../Navbar/Navbar.js';
import IntroOrchideaLeadsPage from './IntroOrchideaLeadsPage/IntroOrchideaLeadsPage';
import LetsBloomLeadsPage from './LetsBloomLeadsPage/LetsBloomLeadsPage';
import Footer from '../../Footer/Footer'
import OrchideaLeadsWidget from './OrchideaLeadsWidget/OrchideaLeadsWidget';
import BannerOrchideaLeadsPage from './BannerOrchideaLeadsPage/BannerOrchideaLeadsPage';
import OrchideaGrowthIsProgressive from './OrchideaGrowthIsProgressive/OrchideaGrowthIsProgressive';
import CaseStudiesOrchideaLeads from './CaseStudiesOrchideaLeads/CaseStudiesOrchideaLeads';
import OrchideaLeadsCounter from './OrchideaLeadsCounter/OrchideaLeadsCounter';
import OrchideaLeadsForecast from '../../utils/OrchideaLeadsForecast/OrchideaLeadsForecast';
import OrchideaLeadsProcess from './OrchideaLeadsProcess/OrchideaLeadsProcess';
import OrchideaLeadsWithWithout from './OrchideaLeadsWithWithout/OrchideaLeadsWithWithout';

export default function OrchideaLeadsPage(){
    const pricingRef = React.useRef(null);

    const goToPricingRef = () => {
        if (pricingRef.current) {
            pricingRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='orchidea-leads-page'>
            <Navbar buttonColor='#7F63FF' buttonColorHover='#9983fc' colorText='#FFFFFF' />
            <IntroOrchideaLeadsPage goToPricingRef={goToPricingRef}/>
            <OrchideaLeadsWithWithout />
            <BannerOrchideaLeadsPage />
            <OrchideaLeadsWidget goToPricingRef={goToPricingRef} />
            <OrchideaLeadsProcess />
            <OrchideaLeadsForecast pricingRef={pricingRef}/>
            <OrchideaGrowthIsProgressive />
            <CaseStudiesOrchideaLeads />
            <OrchideaLeadsCounter />
            <LetsBloomLeadsPage />
            <Footer />
        </div>
    )
}