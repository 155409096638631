import React from 'react';
import './OrchideaHireForecast.css';
import funnel_illustration from './assets/funnel2.svg';
import orchidea_logo_funnel from './assets/orchidea-logo-funnel.svg';

export default function OrchideaHireForecast(){
    const [monthlyBudget, setMonthlyBudget] = React.useState(2500);
    const [salesCycle, setSalesCycle] = React.useState(3);
    const [dealValue, setDealValue] = React.useState('');
    const [prospect, setProspects] = React.useState('0');

    React.useEffect(() => {
        const getProspects = (budget) => {
            if (budget < 1801) {
                return '3,000';
            } else if (budget > 1800 && budget < 2000) {
                return '4,000';
            } else if (budget > 1999 && budget < 2500) {
                return '5,000';
            } else if (budget > 2499 && budget < 3000) {
                return '8,000';
            } else if (budget > 2999 && budget < 4500) {
                return '20,000';
            } else if (budget > 4499 && budget < 6000) {
                return '30,000';
            } else if (budget > 5999) {
                return '50,000';
            } else {
                return 0; // handle default case
            }
        }
        if(monthlyBudget && salesCycle && dealValue !== ''){
            setProspects(getProspects(monthlyBudget));
        }
    }, [monthlyBudget, salesCycle, dealValue, setProspects])

    return (
        <div className='orchidea-hire-forecast'>
            <div className='title'>
                <h2><span>Pricing</span> & Campaign Results Forecast</h2>
            </div>
            <div className='forecast-widget'>
                <div className='forecast-widget--top'>
                    <div className='params'>
                        <div className='parameter' style={{paddingBottom: '20px'}}>
                            <div className='top-parameter'>
                                <div className='number'>
                                    <p>1</p>
                                </div>
                                <p>Monthly budget</p>
                            </div>
                            <div className='input-parameter'>
                                <div className='top-details'>
                                    <select className='currency-select'>
                                        <option>£</option>
                                        <option>$</option>
                                        <option>€</option>
                                    </select>
                                    <p className='monthly-budget-value'>{monthlyBudget}{monthlyBudget > 5999 ? '+' : ''}</p>
                                </div>
                                <div className='input-range-container'>
                                    <input type='range' min='1800' value={monthlyBudget} max='6000' step='100' onChange={(e) => setMonthlyBudget(e.target.value)} />
                                    <div className='selector-input-range' style={{left: (((monthlyBudget - 1800) / 4200) * 100) + '%'}}>
                                        <div className='SelectBtn-input-range'></div>
                                    </div>
                                    <div className="progress-bar-input-range" style={{width: (((monthlyBudget - 1800) / 4200) * 100) + '%'}}></div>
                                </div>
                            </div>
                        </div>

                        <div className='parameter'>
                            <div className='top-parameter'>
                                <div className='number'>
                                    <p>2</p>
                                </div>
                                <p>What is your average deal value?</p>
                            </div>
                            <div className='input-parameter'>
                                <div className='input-parameter-row'>
                                    <div className='input-field'>
                                        <select>
                                            <option>£</option>
                                            <option>$</option>
                                            <option>€</option>
                                        </select>
                                        <input type='text' placeholder='2500' value={dealValue} onChange={(e) => setDealValue(e.target.value)} />
                                    </div>
                                    <p>per <br /> customer</p>
                                </div>
                            </div>
                        </div>

                        <div className='parameter'>
                            <div className='top-parameter' style={{marginBottom: '8px'}}>
                                <div className='number'>
                                    <p>3</p>
                                </div>
                                <p>How long is your average sales cycle?</p>
                            </div>
                            <div className='input-parameter'>
                                <div className='top-details'>
                                    <p className='sales-cycle-value'>{salesCycle}{salesCycle > 9 ? '+' : ''}</p>
                                </div>
                                <div className='input-range-container'>
                                    <input type='range' min='1' value={salesCycle} max='10' step='1' onChange={(e) => setSalesCycle(e.target.value)} />
                                    <div className='selector-input-range' style={{left: (salesCycle * 10) + '%'}}>
                                        <div className='SelectBtn-input-range'></div>
                                    </div>
                                    <div className="progress-bar-input-range" style={{width: (salesCycle * 10) + '%'}}></div>
                                </div>
                                <p className='sales-cycle-label'>month sales cycle</p>
                            </div>
                        </div>
                    </div>
                    <img src={funnel_illustration} alt='funnel' className='funnel' />
                </div>
                <div className='forecast-widget--bottom'>
                    <div className='bottom-bar'>
                        <div className='bar-items'>
                            <div className='item' style={{left: '0px'}}>
                                <img src={orchidea_logo_funnel} alt='orchidea-logo-funnel' />
                                <p className='value'>{prospect}</p>
                                <p className='rate' style={{display: 'none'}}>100%</p>
                            </div>

                            <div className='item' style={{left: '110px'}}>
                                <img src={orchidea_logo_funnel} alt='orchidea-logo-funnel' />
                                <p className='value'>{prospect}</p>
                                <p className='rate'>100%</p>
                            </div>
                            <div className='item' style={{left: '220px'}}>
                                <img src={orchidea_logo_funnel} alt='orchidea-logo-funnel' />
                                <p className='value'>{prospect}</p>
                                <p className='rate'>100%</p>
                            </div>
                            <div className='item' style={{left: '330px'}}>
                                <img src={orchidea_logo_funnel} alt='orchidea-logo-funnel' />
                                <p className='value'>{prospect}</p>
                                <p className='rate'>100%</p>
                            </div>
                            <div className='item' style={{left: '440px'}}>
                                <img src={orchidea_logo_funnel} alt='orchidea-logo-funnel' style={{display: 'none'}} />
                                <div style={{height: '64px', width: '64px'}}></div>
                                <p className='value'>{prospect}</p>
                                <p className='rate'>100%</p>
                            </div>
                            <div className='item' style={{left: '550px'}}>
                                <img src={orchidea_logo_funnel} alt='orchidea-logo-funnel' style={{display: 'none'}} />
                                <div style={{height: '64px', width: '64px'}}></div>
                                <p className='value'>{prospect}</p>
                                <p className='rate'>100%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}