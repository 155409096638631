import React from 'react';
import './CreatePost.css';
import TextEditor from './TextEditor/TextEditor';
import useToken from '../../Hooks/useToken';

export default function CreatePost(props){
    const [type, setType] = React.useState(null);
    const [editorContent, setEditorContent] = React.useState('');
    const [campaignResults, setCampaignResults] = React.useState('');
    const [objectives, setObjectives] = React.useState('');
    const [solution, setSolution] = React.useState('');
    const [problem, setProblem] = React.useState('');
    const {token} = useToken();
    const [blogPost, setBlogPost] = React.useState({
        title: '',
        post_url: '',
        image: '',
        custom_date: '',
        publish_status: 'draft',
        preview: '',
        icon: '',
        author: ''
    });
    const [caseStudyPost, setCaseStudyPost] = React.useState({
        title: '',
        service: 'leads',
        post_url: '',
        image: '',
        custom_date: '',
        publish_status: 'draft',
        icon: '',
        post_intro: '',
        company_industry: '',
        company_website: '',
        company_profile: '',
        company_target_profile: '',
        company_description: '',
        company_performance: '',
        cost_per_lead: '',
        lead_rate: '',
        response_rate: '',
        channels: '1',
        prospects_engaged: '',
        additional_traffic: '',
        open_rate: ''
    });

    const handleSubmit = async (e) =>{
        e.preventDefault();
        const data = type === 'blog' ? blogPost : caseStudyPost;
        data.post_type = type;
        if(type === 'blog'){
            data.content = editorContent;
        } else if (type === 'case-study'){
            data.campaignResults = campaignResults;
            data.objectives = objectives;
            data.solution = solution;
            data.problem = problem;
        }
        const URL = `https://17uttfecb8.execute-api.eu-west-2.amazonaws.com/Prod/posts/create`;
        const options = {
            headers: {
                Authorization: token
            },
            method: 'POST',
            body: JSON.stringify(data)
        }
        try{
            const response = await fetch(URL, options);
            await response.json();
            alert('Post created successfully!');
        } catch (e){
            console.log(e);
            alert('An error occured. The post could not be created.');
        }
        window.location.reload();
    }

    const handleDiscard = (e) => 
    {  
        e.preventDefault();
        window.location.reload();
    }

    return (
        <div className='create-post'>
            <form onSubmit={handleSubmit}>
                <div className='input-group'>
                    <label>Post Type</label>
                    <select onChange={(e) => setType(e.target.value)} defaultValue={'DEFAULT'}>
                        <option disabled={true}value='DEFAULT'>-- Select a post type</option>
                        <option value='blog'>Blog</option>
                        <option value='case-study'>Case Study</option>
                    </select>
                </div>
                {type === 'blog' && <div className='blog-form'>
                    <div className='input-group'>
                        <label>Post Title</label>
                        <input required type='text' value={blogPost.title} onChange={(e) => setBlogPost(prev => ({...prev, title: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Post URL</label>
                        <input required type='text' value={blogPost.post_url} onChange={(e) => setBlogPost(prev => ({...prev, post_url: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Date</label>
                        <input required type='date' value={blogPost.custom_date} onChange={(e) => setBlogPost(prev => ({...prev, custom_date: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Post icon</label>
                        <input required type='text' value={blogPost.icon} onChange={(e) => setBlogPost(prev => ({...prev, icon: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Author</label>
                        <input required type='text' value={blogPost.author} onChange={(e) => setBlogPost(prev => ({...prev, author: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Main image</label>
                        <input required type='text' value={blogPost.image} onChange={(e) => setBlogPost(prev => ({...prev, image: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Post preview (must be between 100-150 characters)</label>
                        <textarea required value={blogPost.preview} onChange={(e) => setBlogPost(prev => ({...prev, preview: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Post content</label>
                        <TextEditor setEditorContent={setEditorContent} initialValue="<p>Hello</p>"/>
                    </div>
                </div>}
                {type === 'case-study' && <div className='blog-form'>
                    <div className='input-group'>
                        <label>Post Title</label>
                        <input type='text' value={caseStudyPost.title} onChange={(e) => setCaseStudyPost(prev => ({...prev, title: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Service</label>
                        <select value={caseStudyPost.service}  onChange={(e) => setCaseStudyPost(prev => ({...prev, service: e.target.value}))}>
                            <option value='leads'>OrchideaLeads</option>
                            <option value='hire'>OrchideaHire</option>
                        </select>
                    </div>
                    <div className='input-group'>
                        <label>Post URL</label>
                        <input type='text' value={caseStudyPost.post_url} onChange={(e) => setCaseStudyPost(prev => ({...prev, post_url: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Post Main Image</label>
                        <input type='text' value={caseStudyPost.image} onChange={(e) => setCaseStudyPost(prev => ({...prev, image: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Post icon</label>
                        <input required type='text' value={caseStudyPost.icon} onChange={(e) => setCaseStudyPost(prev => ({...prev, icon: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Date</label>
                        <input required type='date' value={caseStudyPost.custom_date} onChange={(e) => setCaseStudyPost(prev => ({...prev, custom_date: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Introduction (must be between 100-150 characters)</label>
                        <textarea value={caseStudyPost.post_intro} onChange={(e) => setCaseStudyPost(prev => ({...prev, post_intro: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Company Industry</label>
                        <select value={caseStudyPost.company_industry} onChange={(e) => setCaseStudyPost(prev => ({...prev, company_industry: e.target.value}))}>
                            <option value='Agriculture and Forestry'>Agriculture and Forestry</option>
                            <option value='Automotive and Transportation'>Automotive and Transportation</option>
                            <option value='Construction and Real Estate'>Construction and Real Estate</option>
                            <option value='Consumer Goods and Retail'>Consumer Goods and Retail</option>
                            <option value='Energy and Utilities'>Energy and Utilities</option>
                            <option value='Financial Services'>Financial Services</option>
                            <option value='Information Technology and Software'>Information Technology and Software</option>
                            <option value='Manufacturing and Heavy Industries'>Manufacturing and Heavy Industries</option>
                            <option value='Mining and Metals'>Mining and Metals</option>
                            <option value='Entertainment, Media and Publishing'>Entertainment, Media and Publishing</option>
                            <option value='Telecommunications'>Telecommunications</option>
                            <option value='Travel, Hospitality and Food Services'>Travel, Hospitality and Food Services</option>
                            <option value='Education and Training'>Education and Training</option>
                            <option value='Professional Services'>Professional Services</option>
                            <option value='Government and Public Services'>Government and Public Services</option>
                            <option value='Non-Profit and Social Services'>Non-Profit and Social Services</option>
                            <option value='Fashion and Apparel'>Fashion and Apparel</option>
                            <option value='E-commerce and Digital Services'>E-commerce and Digital Services</option>
                            <option value='Environmental and Sustainability'>Environmental and Sustainability</option>
                        </select>
                    </div>
                    <div className='input-group'>
                        <label>Company Website</label>
                        <input type='text' value={caseStudyPost.company_website} onChange={(e) => setCaseStudyPost(prev => ({...prev, company_website: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Company profile</label>
                        <textarea value={caseStudyPost.company_profile} onChange={(e) => setCaseStudyPost(prev => ({...prev, company_profile: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Company target profile</label>
                        <textarea value={caseStudyPost.company_target_profile} onChange={(e) => setCaseStudyPost(prev => ({...prev, company_target_profile: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Company description</label>
                        <textarea value={caseStudyPost.company_description} onChange={(e) => setCaseStudyPost(prev => ({...prev, company_description: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Cost per lead / candidate (in £)</label>
                        <input type='text' value={caseStudyPost.cost_per_lead} onChange={(e) => setCaseStudyPost(prev => ({...prev, cost_per_lead: e.target.value}))}/>
                    </div>
                    <div className='input-group'>
                        <label>Lead / Candiate rate (in %)</label>
                        <input type='text' value={caseStudyPost.lead_rate} onChange={(e) => setCaseStudyPost(prev => ({...prev, lead_rate: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Response rate (in %)</label>
                        <input type='text' value={caseStudyPost.response_rate} onChange={(e) => setCaseStudyPost(prev => ({...prev, response_rate: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Open rate (in %)</label>
                        <input type='text' value={caseStudyPost.open_rate} onChange={(e) => setCaseStudyPost(prev => ({...prev, open_rate: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Number of channels used</label>
                        <select value={caseStudyPost.channels} onChange={(e) => setCaseStudyPost(prev => ({...prev, channels: e.target.value}))}>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                        </select>
                    </div>
                    <div className='input-group'>
                        <label>Number of prospects engaged</label>
                        <input type='text' value={caseStudyPost.prospects_engaged} onChange={(e) => setCaseStudyPost(prev => ({...prev, prospects_engaged: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Total additional traffic / connections?</label>
                        <input type='text' value={caseStudyPost.additional_traffic} onChange={(e) => setCaseStudyPost(prev => ({...prev, additional_traffic: e.target.value}))} />
                    </div>
                    <div className='input-group'>
                        <label>Problem</label>
                        <TextEditor setEditorContent={setProblem} initialValue="<p>Problem.</p>"/>
                    </div>
                    <div className='input-group'>
                        <label>Our Solution</label>
                        <TextEditor setEditorContent={setSolution} initialValue="<p>Our solution.</p>"/>
                    </div>
                    <div className='input-group'>
                        <label>Objectives</label>
                        <TextEditor setEditorContent={setObjectives} initialValue="<p>Objectives.</p>"/>
                    </div>
                    <div className='input-group'>
                        <label>Campaign results</label>
                        <TextEditor setEditorContent={setCampaignResults} initialValue="<p>Campaign results.</p>"/>
                    </div>
                </div>}
                {type !== null && 
                <div className='buttons'>
                    <button type='submit' style={{marginRight: '15px'}}>Create post</button>
                    <button onClick={handleDiscard}>Discard</button>
                </div>}
            </form>
        </div>
    );
}