import React from 'react';
import './OrchideaLeadsProcess.css';
import step1_illustration from './assets/step1.png';
import step2_illustration from './assets/step2.svg';
import step3_illustration from './assets/step3.svg';
import step4_illustration from './assets/step4.svg';
import step5_illustration from './assets/step5.svg';

export default function OrchideaLeadsProcess(){
    return (
        <div className='orchidea-leads-process'>
            <div className='orchidea-leads-process--inside'>
                <div className='title-container'>
                    <h1>Process</h1>
                </div>
                <div className='numbers-container'>
                    <div className='numbers'>
                        <div className='number' style={{left: '-1px'}}>
                            <p>1</p>
                        </div>
                        <div className='number' style={{left: '230px'}}>
                            <p>2</p>
                        </div>
                        <div className='number' style={{left: '460px'}}>
                            <p>3</p>
                        </div>
                        <div className='number' style={{left: '700px'}}>
                            <p>4</p>
                        </div>
                        <div className='number' style={{right: '-1px'}}>
                            <p>5</p>
                        </div>
                    </div>
                </div>
                <div className='items'>
                    <div className="item">
                        <img src={step1_illustration} alt='step1-illustraiton' />
                        <p>Initial Strategy Call & information gathering</p>
                    </div>
                    <div className="item">
                        <img src={step2_illustration} alt='step2-illustraiton' />
                        <p>Identify & source target audience, segment lists & plan outreach strategy</p>
                    </div>
                    <div className="item">
                        <img src={step3_illustration} alt='step3-illustraiton' />
                        <p>We handle Multi-channel Outbound Campaigns</p>
                    </div>
                    <div className="item">
                        <img src={step4_illustration} alt='step4-illustraiton' />   
                        <p>Hot Lead notifications & Weekly Updates</p>
                    </div>
                    <div className="item">
                        <img src={step5_illustration} alt='step5-illustraiton' />
                        <p>Monthly Reporting & campaign optimisation</p>
                    </div>
                </div>
            </div>
        </div>
    );
}