import './App.css';
import { BrowserRouter, Outlet, Routes, Route  } from 'react-router-dom';
import Page
 from './Pages/Page';
import HomePage from './Pages/HomePage/HomePage';
import OrchideaLeadsPage from './Pages/OrchideaLeadsPage/OrchideaLeadsPage';
import OrchideaHirePage from './Pages/OrchideaHirePage/OrchideaHirePage';
import OrchideaSpendPage from './Pages/OrchideaSpendPage/OrchideaSpendPage';
import ContactPage from './Pages/ContactPage/ContactPage';
import AboutPage from './Pages/AboutPage/AboutPage';
import Login from './Login/Login';
import ScrollToTop from './ScrollToTop/ScrollToTop';
import PricingPage from './Pages/PricingPage/PricingPage';
import CaseStudiesPage from './Pages/CaseStudiesPage/CaseStudiesPage';
import BlogAdmin from './BlogAdmin/BlogAdmin';
import BlogsPage from './Pages/BlogsPage/BlogsPage';
import BlogPost from './Pages/BlogsPage/BlogPost/BlogPost';
import NotFound from './Pages/NotFound/NotFound';
import SetPassword from './BlogAdmin/SetPasswordAdmin/SetPasswordAdmin';
import CaseStudyPost from './Pages/CaseStudiesPage/CaseStudyPost/CaseStudyPost';

function App() {

  return (
    <div className="App">
      <Outlet />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>

          <Route path="/" element={<Page />}>
            <Route path='' element={<HomePage />} />
            <Route path='leads' element={<OrchideaLeadsPage />} />
            <Route path='hire' element={<OrchideaHirePage />} />
            <Route path='spend' element={<OrchideaSpendPage />} />
            <Route path='contact' element={<ContactPage />} />
            <Route path='about' element={<AboutPage />} />
            <Route path='log-in' element={<Login />} />
            <Route path='pricing' element={<PricingPage />} />
            <Route path='case-studies' element={<CaseStudiesPage />} />
            <Route path='case-studies/post/:id' element={<CaseStudyPost />} />
            <Route path='blog' element={<BlogsPage />} />
            <Route path='blog/post/:id' element={<BlogPost />} />
            <Route path='admin' element={<BlogAdmin />} />
            <Route path='admin/set-password' element={<SetPassword />} />
          </Route>

          <Route path="*" element={<NotFound />}/>

          {/* <Route path="*" element={<NotFound/>}/> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
