import React from 'react';
import './IntroOrchideaHirePage.css';
import orchidea_hire_intro_illustration from './assets/orchidea-hire-intro.svg';

export default function IntroOrchideaHirePage(){
    return (
        <div className="orchidea-hire-intro">
            <img src={orchidea_hire_intro_illustration} alt="orchidea-hire" />
            <div className="orchidea-hire-intro-content">
                <h1>Orchidea<span>Hire</span></h1>
                <p className='subtitle'>for recruiters</p>
                <img src={orchidea_hire_intro_illustration} alt="orchidea-hire" className='illustration-responsive' />
                <p><span>Finding the right candidates and growing your team can be challenging and time-consuming, but with the help of a reputable recruitment agency, it doesn't have to be!</span></p>
                <p>Our experts will fully manage your Headhunting, and inbound recruitment search process over multiple channels via multiple contact sourcings & networks.</p>
                <p>Our experts will fully manage your Headhunting, and inbound recruitment search process over multiple channels via multiple contact sourcings & networks.</p>
                <p><span>Get the best talent with Orchidea Hire!</span></p>
                <div className="orchidea-hire-intro-buttons">
                    <button onClick={() => window.location.href = '/contact'}>Let's Bloom</button>
                    <button className="learn-more" onClick={() => window.location.href = '/pricing'}>See pricing</button>
                </div>
            </div>
        </div>
    );
}