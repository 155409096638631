import React from 'react';
import './HomePage.js';
import Navbar from '../../Navbar/Navbar.js';
import IntroHomePage from './IntroHomePage/IntroHomePage.js';
import PartnersHomePage from './PartnersHomePage/PartnersHomePage.js';
import HomePageServices from './HomePageServices/HomePageServices.js';
import BrandsHomePage from './BrandsHomePage/BrandsHomePage.js';
import OrchideaLeads from './OrchideaLeads/OrchideaLeads.js';
import OrchideaHireIntro from './OrchideaHireIntro/OrchideaHireIntro.js';
import OrchideaSpendIntro from './OrchideaSpendIntro/OrchideaSpendIntro.js';
import LetsBloomSection from '../../utils/LetsBloomSection/LetsBloomSection.js'
import ResourcesHomePage from './ResourcesHomePage/ResourcesHomePage.js';
import Footer from '../../Footer/Footer.js';

export default function HomePage(){
    return (
        <div className='home-page'>
            <div style={{background: 'linear-gradient(50.37deg, #efeeef 2.97%, rgba(229, 228, 232, 0) 77.1%)'}}>
                <Navbar buttonColor='#d8f7ee' buttonColorHover='#d9f3ec' colorText='#25262B' />
                <IntroHomePage />
            </div>
            <PartnersHomePage />
            <HomePageServices />
            <BrandsHomePage />
            <OrchideaLeads />
            <OrchideaHireIntro />
            <OrchideaSpendIntro />
            <LetsBloomSection />
            <ResourcesHomePage />
            <Footer />
        </div>
    )
}