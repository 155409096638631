import React from 'react';
import './NavbarDropdown.css';
import arrow_down from './assets/chevron-down.svg';
import arrow_up from './assets/chevron-up.svg';

export default function NavbarDropdown(props){
    const [itemsDisplayed, setItemsDisplayed] = React.useState(false);

    return (
        <div className='navbar-dropdown'>
            <div className='navbar-dropdown--label' onClick={() => setItemsDisplayed(prev => !prev)}>
                <p>{props.label}</p>
                <img src={itemsDisplayed ? arrow_up : arrow_down} alt='arrow-down' />
            </div>
            <div className='navbar-dropdown--items' style={{display: itemsDisplayed ? 'block' : 'none'}}>
                {props.children}
            </div>
        </div>
    )
}