import React from 'react';
import './IntroOrchideaSpendPage.css';
import orchidea_spend_intro_illustration from './assets/orchidea-spend-intro.svg';

export default function IntroOrchideaSpendPage(props){
    return (
        <div className="orchidea-spend-intro">
            <img src={orchidea_spend_intro_illustration} alt="orchidea-spend" />
            <div className="orchidea-spend-intro-content">
                <h1>Orchidea<span>Spend</span></h1>
                <p className='subtitle'>for ad specialists</p>
                <img src={orchidea_spend_intro_illustration} alt="orchidea-spend" className='illustration-responsive' />
                <p><span>We understand organic growth takes time, both in nurturing leads and candidates and to get the funnel flowing with consistent high volume motion!</span></p>
                <p>Luckily we thought of that too! With Orchidea Spend, we take care of your SEO, PPC, and social media management to exponentially augment and amplify the speed of results by up to 50x!</p>
                <p>Our experts are here to set up, execute, and manage from end-to-end all of your Ad spend on Google & Social media.</p>
                <p>Audit and improve your search ranking, and help evolve your business to the next level overall.</p>
                <p><span>Make the organic work step to the next level!</span></p>
                <div className="orchidea-spend-intro-buttons">
                    <button onClick={() => window.location.href = '/contact'}>Let's Bloom</button>
                    <button className="learn-more" onClick={props.goToPricingRef}>See pricing</button>
                </div>
            </div>
        </div>
    );
}